define("commander/mixins/shift-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    i18n: Ember.inject.service(),
    actions: {
      newShift(vehicleNumber) {
        this.store.createRecord('shift', {
          startTime: new Date(),
          endTime: moment().add(8, 'hours').toDate(),
          vehicleNumber: vehicleNumber
        });
      },

      cancelNewShift(shift) {
        this.shifts.removeObject(shift);
        shift.deleteRecord();
      },

      removeShift(shift) {
        shift.destroyRecord().then(() => {
          this.shifts.removeObject(shift);

          this._removeShiftsBreaksFromList(shift);
        }).catch(err => {
          this.shiftErrorPopup('remove', err.errors[0].detail);
        });
      },

      shiftAdded(shift) {
        console.log('SHIFT', shift);
      }

    },

    shiftErrorPopup(operation, code) {
      this.notifications.error(this.i18n.t(`shiftList.errors.${operation}.${code}`), {
        cssClasses: 'commander-notification',
        autoClear: true,
        clearDuration: 5000
      });
    },

    _removeShiftsBreaksFromList(shift) {
      // this block of code should probably be in break-list mixin. oh well..
      if (shift.get('breaks.length') > 0 && this.breaks) {
        // my god, there isn't an easier way to remove bunch of objects from an array ?!
        // TODO something like this should do the trick: (but I don't know how to test it...)
        //  breaks.removeObjects(shift.get('breaks').map(sb => breaks.find(b => sb.id === b.id)).filter(Boolean));
        const breaks = this.breaks;
        const ids = shift.get('breaks').map(br => `${br.id}`);

        for (let i = breaks.get('length') - 1; i >= 0; i -= 1) {
          if (ids.indexOf(breaks.objectAt(i).get('id')) > -1) {
            breaks.removeAt(i);
          }
        }
      }
    }

  });

  _exports.default = _default;
});