define("commander/models/shift", ["exports", "@ember-data/model", "commander/helpers/local-time-format"], function (_exports, _model, _localTimeFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    vehicleNumber: (0, _model.attr)('string'),
    startTime: (0, _model.attr)('date'),
    endTime: (0, _model.attr)('date'),
    completion: (0, _model.attr)(),
    breaks: (0, _model.attr)(),
    startTimeLocal: Ember.computed('startTime', function () {
      const time = this.startTime;
      return (0, _localTimeFormat.localDateTimeString)(time);
    }),
    endTimeLocal: Ember.computed('endTime', function () {
      const time = this.endTime;
      return (0, _localTimeFormat.localDateTimeString)(time);
    }),
    isDriving: Ember.computed('startTime', 'endTime', function () {
      const start = this.startTime.getTime();
      const end = this.endTime.getTime();
      const now = new Date().getTime();
      return now < end && now > start;
    }),
    duration: Ember.computed('startTime', 'endTime', function () {
      const start = this.startTime.getTime();
      const end = this.endTime.getTime();
      return Math.floor((end - start) / 1000);
    })
  });

  _exports.default = _default;
});