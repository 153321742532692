define("commander/services/map-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  // TODO may be we should try to move here as much map actions as possible?
  class MapServiceService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "focusedVehicles", null);

      _defineProperty(this, "selectedVehicles", null);

      _defineProperty(this, "selectedTrip", null);

      _defineProperty(this, "hoveredStop", null);

      _defineProperty(this, "hoveredTrip", null);

      _defineProperty(this, "hoveredRoute", null);
    }

    // the route will be highlighted
    setFocusedVehicles(numbers) {
      this.set('focusedVehicles', numbers);
    }

    setSelectedVehicles(numbers) {
      this.set('selectedVehicles', numbers);
    }

    setSelectedTrip(trip) {
      this.set('selectedTrip', trip);
    }

    dropSelectedTrip(trip) {
      if (this.selectedTrip && this.selectedTrip.id === trip.id) {
        this.set('selectedTrip', null);
      }
    }

    setHoveredStop(stop) {
      this.set('hoveredStop', stop);
    }

    setHoveredTrip(trip) {
      this.set('hoveredTrip', trip);
    }

    setHoveredRoute(route) {
      this.set('hoveredRoute', route);
    }

  }

  _exports.default = MapServiceService;
});