define("commander/pods/components/dispatch-starter/component", ["exports", "@glimmer/component", "commander/config/environment", "commander/utils/utils"], function (_exports, _component, _environment, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.dispatchFailDefaultOption = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const DISPATCH_FAIL_ACTIONS = _environment.default.APP.tripModification.dispatchFailActions;
  const dispatchFailDefaultOption = DISPATCH_FAIL_ACTIONS[0];
  _exports.dispatchFailDefaultOption = dispatchFailDefaultOption;
  let DispatchStarterComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember.computed('args.isExternal'), _dec8 = Ember.computed('duration'), _dec9 = Ember.computed, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, (_class = (_temp = class DispatchStarterComponent extends _component.default {
    get failActionOptions() {
      // last option SHOULD be `cancel-route`. Exclude it if the route is NOT external
      return this.args.isExternal ? DISPATCH_FAIL_ACTIONS : DISPATCH_FAIL_ACTIONS.slice(0, -1);
    }

    get formDisabled() {
      return !/^[1-9]\d*$/.test(`${this.duration}`);
    } // ---- vehicle selector functions ----


    get allVehicles() {
      return this.store.peekAll('vehicle');
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notifications", _descriptor, this);

      _initializerDefineProperty(this, "i18n", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "failAction", _descriptor4, this);

      _initializerDefineProperty(this, "duration", _descriptor5, this);

      _initializerDefineProperty(this, "excludedVehicles", _descriptor6, this);

      this.failAction = this.args.failAction || DISPATCH_FAIL_ACTIONS[0];
      this.duration = this.args.duration || 8;
      this.excludedVehicles = this.args.excludedVehicles || [];
    }

    onSubmit() {
      if (!this.duration) {
        this.notifications.error(this.i18n.t('dispatching.errors.durationRequired'), {
          cssClasses: 'commander-notification',
          autoClear: true,
          clearDuration: 5000
        });
        return;
      }

      this.args.submit(this.failAction, this.duration ? this.duration * 60 : null, this.excludedVehicles);
    }

    onVehicleSelect(value) {
      this.excludedVehicles = [...this.excludedVehicles, ...(0, _utils.parseVehicleOption)(value)];
      this.notifyChange();
    }

    onVehicleRemove(value) {
      this.excludedVehicles = this.excludedVehicles.filter(i => i !== value);
      this.notifyChange();
    }

    onVehicleClear() {
      this.excludedVehicles = [];
      this.notifyChange();
    }

    onDurationChanged(value) {
      this.duration = value.target.value;
      this.notifyChange();
    }

    onFailActionSelect(value) {
      this.failAction = value.target.value;
      this.notifyChange();
    }

    notifyChange() {
      // notify all values
      if (this.args.onChange) {
        this.args.onChange({
          failAction: this.failAction,
          duration: this.duration,
          excludedVehicles: this.excludedVehicles
        });
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notifications", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "i18n", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "failAction", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "duration", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "excludedVehicles", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "failActionOptions", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "failActionOptions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "formDisabled", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "formDisabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "allVehicles", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "allVehicles"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSubmit", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onVehicleSelect", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "onVehicleSelect"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onVehicleRemove", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "onVehicleRemove"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onVehicleClear", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "onVehicleClear"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDurationChanged", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "onDurationChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onFailActionSelect", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "onFailActionSelect"), _class.prototype)), _class));
  _exports.default = DispatchStarterComponent;
});