define("commander/helpers/hhmmss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hhmmss = hhmmss;
  _exports.default = void 0;

  /**
   * Converts a date string or date object into time string hhmmss.
   * @param {Array} params First item in array is the one which is converted.
   */
  function hhmmss(params) {
    const date = params[0];

    if (date) {
      return moment(date).format('LTS');
    }

    return '';
  }

  var _default = Ember.Helper.helper(hhmmss);

  _exports.default = _default;
});