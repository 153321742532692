define("commander/pods/components/capacities-list/component", ["exports", "@glimmer/component", "commander/config/environment"], function (_exports, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_CAPACITIES = _environment.default.APP.capacities; // only lists capacities defined in the ENV config

  class CapacitiesList extends _component.default {
    get orderedCapacities() {
      const excludeCapacities = this.args.excludeCapacities || [];
      const capacities = this.args.capacities || [];
      const allCapacities = this.args.allCapacities || [];
      const capacityDisplaySettings = allCapacities.reduce((acc, curr) => {
        if (curr.display) {
          acc[curr.id] = curr.display;
        }

        return acc;
      }, {});
      const translatedCapacityNames = allCapacities.reduce((acc, curr) => {
        acc[curr.id] = curr.name;
        return acc;
      }, {}); // If display defined on garage -> do not exclude

      const excl = excludeCapacities.filter(id => !capacityDisplaySettings[id]);
      const display = Object.assign({}, DEFAULT_CAPACITIES, capacityDisplaySettings);
      const caps = (capacities || []).filter(c => !excl.includes(c.unitId) && !!display[c.unitId]);
      return caps.map(c => {
        return {
          name: translatedCapacityNames[c.unitId],
          icon: display[c.unitId].icon,
          text: display[c.unitId].text,
          count: c.count || 0
        };
      });
    }

  }

  _exports.default = CapacitiesList;
});