define("commander/pods/components/vehicle-itinerary/component", ["exports", "commander/config/environment", "commander/utils/route"], function (_exports, _environment, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    mapService: Ember.inject.service('map-service'),
    displayWindowService: Ember.inject.service('display-window'),
    exportCsv: Ember.inject.service('export-csv'),
    exportEnabled: _environment.default.APP.exportEnabled,
    shifts: null,
    // in-param
    routeData: {},
    // in-param includes trips, routes
    itinerary: Ember.A([]),
    itineraryHeaders: {
      // for export csv
      type: 'Event type',
      time: 'Event time',
      address: 'Address',
      tripId: 'Trip Id',
      routeId: 'Route Id',
      load: 'Current load'
    },

    didReceiveAttrs() {
      Ember.run.throttle(this, this.updateItineraryEvents, 200);
    },

    /**
     * Generates itinerary items and saves array into property 'itinerary'.
     * Itinerary item format:
     * {
     *  item: original model item (e.g. stop / shift / break)
     *  time: time of event (e.g. stop plannedTime or break start time)
     *  type: type of the item model (e.g. stop / date / shift / break)
     *  subType: subtype, optional (e.g. pick / drop / flex)
     *  route: optional (present on stop type items)
     *  trip: optional (present on pick/drop subtype items)
     * }
     */
    updateItineraryEvents() {
      let items = [];
      const routes = this.routeData.routes;
      const trips = this.routeData.trips || [];
      const shifts = this.shifts;

      if (routes) {
        items = items.concat(this.getStopItems(routes, trips));
      }

      if (shifts) {
        items = items.concat(this.getShiftItems(shifts));
      } // add unique keys for ui rendering


      this.addUniqueKeys(items);
      const sortedItems = this.sortItinerary(items);
      this.generateLoadData(sortedItems);
      this.addDateRowItems(sortedItems);
      this.set('itinerary', Ember.A([]).addObjects(sortedItems));
    },

    sortItinerary(items) {
      return items.sort(function (a, b) {
        // order by time, secondary ordering for trips: subType ('pick'/'drop')
        const diff = a.time.getTime() - b.time.getTime();

        if (diff === 0 && a.type === 'stop' && b.type === 'stop') {
          // drop before pick (because of capacity calc), flex always first (no change to capacity)
          if (a.subType === 'pick' || a.subType === 'drop' && b.subType === 'flex') {
            return 1;
          } else if (a.subType === 'flex' || a.subType === 'drop' && b.subType === 'pick') {
            return -1;
          } else {
            // Default for future subTypes
            return 0;
          }
        }

        return diff;
      });
    },

    _isTimeInsideDisplayWindow(time) {
      const win = this.displayWindowService.getWindow();

      if (win) {
        return time.getTime() >= win.start.getTime() && time.getTime() <= win.end.getTime();
      }

      return false;
    },

    addUniqueKeys(items) {
      items.forEach(i => i.uniqueKey = `${i.type}-${i.subType ? i.subType : ''}-${i.item ? i.item.id : ''}`);
    },

    // add new date row into itinerary when date changes
    addDateRowItems(items) {
      let previousDate;

      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const d = new Date(item.time.getFullYear(), item.time.getMonth(), item.time.getDate());

        if (!previousDate || previousDate.getTime() < d.getTime()) {
          items.splice(i, 0, {
            item: null,
            time: d,
            type: 'date',
            subType: null
          });
          previousDate = d;
          i++;
        }
      }

      return items;
    },

    /**
     * Add load data to each itinerary item with type stop.
     * Add as property 'totalCapacities'.
     * Load data is dictionary with capacity unique id as key and cumulative amount as value.
     *
     * @param {*} items Itinerary items
     */
    generateLoadData(items) {
      let previousLoad = {};
      items.forEach(item => {
        if (item.type === 'stop') {
          this.addLoadData(previousLoad, item.trip ? item.trip.get('capacity') : [], item.subType);
          item.totalCapacities = Object.assign({}, previousLoad); // remove zero capacities

          this.removeZeroCapacities(previousLoad);
        }
      });
    },

    /**
     * Remove keys with value zero.
     * @param {*} loadObject items's capacity object.
     */
    removeZeroCapacities(loadObject) {
      const keys = Object.keys(loadObject);
      keys.forEach(k => {
        if (loadObject[k] === 0) {
          delete loadObject[k];
        }
      });
    },

    /**
     *
     * @param {*} previous Previous load data item
     * @param {*} capacities Trip object capacities.
     * @param {*} subType Pick, drop or flex
     */
    addLoadData(previous, capacities, subType) {
      let direction = 0;

      if (subType === 'pick') {
        direction = 1;
      } else if (subType === 'drop') {
        direction = -1;
      }

      if (capacities) {
        capacities.forEach(c => {
          const count = direction * c.count;
          previous[c.unitId] = previous[c.unitId] || previous[c.unitId] === 0 ? previous[c.unitId] + count : count;
        });
      }
    },

    /**
     * Gets items from route.stops from routes
     * @param routes
     * @param trips
     */
    getStopItems(routes, trips) {
      const tripMap = trips.reduce((acc, trip) => {
        acc[trip.stop.pick.id] = trip;
        acc[trip.stop.drop.id] = trip;
        return acc;
      }, {});
      const items = [];
      routes.forEach(route => {
        route.stops.forEach(stop => {
          if (stop.type === 'flex') {
            items.addObject({
              item: stop,
              time: new Date(stop.plannedTime),
              type: 'stop',
              subType: 'flex',
              route
            });
          } else if (['pick', 'drop'].includes(stop.type)) {
            const trip = tripMap[stop.id];
            (true && !(!!trip) && Ember.assert(`Trip should exist for stop ${stop.id}`, !!trip));
            items.addObject({
              item: stop,
              time: new Date(stop.plannedTime),
              type: 'stop',
              subType: stop.type,
              route,
              trip
            });
          }
        });
      });
      return items;
    },

    getShiftItems(shifts) {
      const items = [];
      shifts.forEach(item => {
        // shift start
        const obj = {
          item: item,
          time: item.get('startTime'),
          type: 'shift',
          subType: 'start'
        };
        items.addObject(obj); // shift end

        const obj2 = {
          item: item,
          time: item.get('endTime'),
          type: 'shift',
          subType: 'end'
        };
        items.addObject(obj2); // breaks (constructing directly from shift data)

        const breaks = item.get('breaks').reduce((all, br) => {
          if (this._isTimeInsideDisplayWindow(new Date(br.plannedStart))) {
            all.push({
              item: br,
              time: new Date(br.plannedStart),
              type: 'break'
            });
          }

          return all;
        }, []);
        items.addObjects(breaks);
      });
      return items;
    },

    _exportCSV() {
      const win = this.displayWindowService.getWindow();
      let filename = 'itinerary';
      let vehicleNumber = this.get('vehicle.number');

      if (win) {
        filename = `vehicle-${vehicleNumber}-itineray-${win.start.toLocaleString()}-${win.end.toLocaleString()}`;
        filename = filename.split(' ').join('');
      }

      const itinerary = this.itinerary;

      let getType = item => {
        let type = `${item.type} ${item.subType}`;

        if (item.type === 'stop') {
          type = item.subType;
        } else if (item.type === 'break') {
          type = `${item.type} ${item.item.duration / 60}min`;
        }

        return type;
      };

      let createLoad = totalCapacities => {
        let capaSting = '';

        for (let key in totalCapacities) {
          let defintition = _environment.default.APP.capacities[key];

          if (defintition) {
            capaSting += `${defintition.name} ${totalCapacities[key]} `;
          }
        }

        return capaSting;
      };

      let itemsFormatted = [];
      itinerary.forEach(item => {
        if (item.type !== 'date') {
          itemsFormatted.push({
            type: getType(item),
            time: `${item.time.toLocaleTimeString()} ${item.time.toLocaleDateString()}`,
            address: item.item.location ? _route.default.formattedAddress(item.item.location) : '',
            tripId: item.trip ? `${item.trip.id}` : '',
            routeId: item.route ? `${item.route.id}` : '',
            load: createLoad(item.totalCapacities || {})
          });
        }
      });
      this.exportCsv.exportCSVFile(this.itineraryHeaders, itemsFormatted, filename, `Vehicle: ${vehicleNumber}`);
    },

    actions: {
      onStopHover(stop, routeId) {
        // highlight corresponding stops in the list
        this.itinerary.filter(i => i.trip && i.type === 'stop' && i.item.id === stop.id).forEach(i => i.trip.highlighted = true);
        const route = this.routeData.routes.find(r => r.get('id') === `${routeId}`);
        this.mapService.setHoveredStop(stop);
        this.mapService.setHoveredRoute(route);
      },

      onStopHoverOut() {
        // un-highlight all stops in the list
        this.itinerary.filter(i => i.type === 'stop' && i.item.trip).forEach(i => i.item.trip.set('highlighted', false));
        this.mapService.setHoveredStop(null);
        this.mapService.setHoveredRoute(null);
      },

      export() {
        this._exportCSV();
      }

    }
  });

  _exports.default = _default;
});