define("commander/services/export-csv", ["exports", "commander/utils/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CSV_DELIMINATOR = ';'; // https://medium.com/@danny.pule/export-json-to-csv-file-using-javascript-a0b7bc5b00d2

  var _default = Ember.Service.extend({
    formatData(headers, itemsNotFormatted) {
      let itemsFormatted = [headers];
      itemsNotFormatted.forEach(item => {
        let itemFormatted = {};

        for (let key in headers) {
          if (headers.hasOwnProperty(key)) {
            itemFormatted[key] = _utils.default.gp(item, key);
          }
        }

        itemsFormatted.push(itemFormatted);
      });
      return itemsFormatted;
    },

    convertToCSV(objArray, title = '') {
      const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
      let str = title + '\r\n';

      for (let i = 0; i < array.length; i++) {
        let line = '';

        for (let index in array[i]) {
          if (line !== '') {
            line += CSV_DELIMINATOR;
          }

          line += array[i][index];
        }

        str += line + '\r\n';
      }

      return str;
    },

    exportCSVFile(headers, itemsNotFormatted, fileTitle, title) {
      const items = this.formatData(headers, itemsNotFormatted, title);
      const jsonObject = JSON.stringify(items);
      const csv = this.convertToCSV(jsonObject, title);
      const exportedFileName = fileTitle + '.csv' || 'export.csv';
      const blob = new Blob([csv], {
        type: 'text/csv;charset=utf-8;'
      });

      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFileName);
      } else {
        let link = document.createElement('a');

        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', exportedFileName);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    }

  });

  _exports.default = _default;
});