define("commander/pods/components/map-trip-endpoint/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+ExiXh8G",
    "block": "{\"symbols\":[\"@color\",\"@location\",\"@hover\"],\"statements\":[[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"circle-marker-layer\",[],[[\"@radius\",\"@color\",\"@fillOpacity\",\"@location\"],[14,[32,1],0.4,[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"circle-marker-layer\",[],[[\"@radius\",\"@color\",\"@stroke\",\"@fillOpacity\",\"@location\"],[7,[32,1],true,1,[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "commander/pods/components/map-trip-endpoint/template.hbs"
    }
  });

  _exports.default = _default;
});