define("commander/helpers/distance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(params => `${Math.round(params[0] / 100) / 10} km`);

  _exports.default = _default;
});