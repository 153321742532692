define("commander/pods/components/messenger-panel/component", ["exports", "jquery", "commander/utils/utils"], function (_exports, _jquery, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    i18n: Ember.inject.service(),
    notifications: Ember.inject.service(),
    messenger: Ember.inject.service(),
    classNames: ['messenger-panel'],
    formVehicles: [],
    formExpiration: 48,
    formMessage: '',
    showCalendar: false,
    selectedExpDate: null,
    customOption: 'custom',
    isFetchingMessages: false,
    expirationOptions: [1, 5, 12, 24, 48],

    // values in hours at the moment
    init() {
      this._super(...arguments);

      this.set('vehicles', this.get('allvehicles.vehicles'));
      this.messenger.setMessenger(this);
      this.set('selectedExpDate', moment().add(1, 'days').toDate());
    },

    openMessenger(vehicle) {
      (0, _jquery.default)('span[href="#collapse-rightPanel"].collapsed').click(); // expand the right panel if collapsed

      (0, _jquery.default)('span[href="#messenger-title"]').click(); // force messenger to open

      this.formVehicles.setObjects([vehicle]);
    },

    actions: {
      onVehicleSelect(value) {
        this.formVehicles.pushObjects((0, _utils.parseVehicleOption)(value));
      },

      onVehicleRemove(value) {
        this.formVehicles.removeObject(value);
      },

      onVehicleClear() {
        this.formVehicles.clear();
      },

      onExpirationSelect(val) {
        this.set('formExpiration', val);
        this.set('showCalendar', val === this.customOption);
      },

      onCustomExpirationSelect(val) {
        this.set('selectedExpDate', val);
      },

      onSend() {
        const expiration = this.formExpiration;
        const expirationDate = expiration === this.customOption ? this.selectedExpDate : moment().add(expiration, 'hours').toDate();
        const message = this.store.createRecord('message', {
          id: +moment(),
          //TODO: we do not store messages at the moment, so set id to the local timestamp for now
          vehicles: this.formVehicles,
          expiration: expirationDate,
          message: this.formMessage
        });
        message.save().then(() => {
          // clean-up the form
          this.set('formVehicles', []);
          this.set('formExpiration', 48);
          this.set('formMessage', ''); // remove the record

          message.unloadRecord(); // show the notification

          this.notifications.success(this.i18n.t('messenger.success'), {
            cssClasses: 'commander-notification',
            autoClear: true,
            clearDuration: 5000
          }); // update received through ws
        }).catch(e => {
          console.log(e); // remove the record

          message.unloadRecord(); // show the notification

          this.notifications.error(this.i18n.t('messenger.fail'), {
            cssClasses: 'commander-notification',
            autoClear: true,
            clearDuration: 5000
          });
        });
      }

    }
  });

  _exports.default = _default;
});