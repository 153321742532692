define("commander/pods/components/vehicle-number/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { computed } from '@ember/object';
  class VehicleNumber extends _component.default {
    // @computed('args.trip.routeId', 'args.trip.dispatchStrategyIncludedVehicles', 'args.route.dispatchStrategyIncludedVehicles')
    get includedVehicles() {
      let vehicles = [];

      if (this.args.route) {
        vehicles = this.args.route.dispatchStrategyIncludedVehicles;
      } else if (this.args.trip) {
        vehicles = this.args.trip.dispatchStrategyIncludedVehicles;
      }

      return [...new Set(vehicles)];
    }

  }

  _exports.default = VehicleNumber;
});