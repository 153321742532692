define("commander/pods/auth-callback/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    auth: Ember.inject.service('auth'),

    beforeModel() {
      // check if we are authenticated
      // parse the url hash that comes back from auth0
      // if authenticated on login, redirect to the dashboard
      Ember.get(this, 'auth').handleAuthentication().then(() => this.transitionTo('index'));
    }

  });

  _exports.default = _default;
});