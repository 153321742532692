define("commander/pods/components/attributes-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3lQO76rE",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n    \"],[1,[32,0,[\"localizedAttributesString\"]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "commander/pods/components/attributes-list/template.hbs"
    }
  });

  _exports.default = _default;
});