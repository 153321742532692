define("commander/pods/components/timerange-picker/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    displayWindowService: Ember.inject.service('display-window'),
    selectedFromValue: -1,
    fromUnit: 'hours',
    selectedToValue: 1,
    toUnit: 'hours',

    init() {
      this._super(...arguments);

      this.updateLocale();
    },

    updateLocale() {
      const i18n = this.i18n;
      this.set('timeRangeDropdown', [{
        title: i18n.t('datetime.ranges.hours'),
        unit: 'hours'
      }, {
        title: i18n.t('datetime.ranges.days'),
        unit: 'days'
      }]);
      this.set('selectedFromTitle', `datetime.ranges.${this.fromUnit}`);
      this.set('selectedToTitle', `datetime.ranges.${this.toUnit}`);
    },

    actions: {
      rangeUnit(anchor, unit) {
        if (anchor === 'from') {
          this.set('fromUnit', unit);
          this.updateLocale();
        } else {
          this.set('toUnit', unit);
          this.updateLocale();
        }
      },

      fromValue(value) {
        this.set('selectedFromValue', value);
      },

      toValue(value) {
        this.set('selectedToValue', value);
      },

      apply() {
        let start = moment();
        let end = moment();
        start = start.add(this.selectedFromValue, this.fromUnit);
        end = end.add(this.selectedToValue, this.toUnit);
        this.displayWindowService.setRealtimeWindow(start.toDate(), end.toDate());
      },

      cancel() {}

    }
  });

  _exports.default = _default;
});