define("commander/pods/components/route-table-row/component", ["exports", "@glimmer/component", "commander/config/environment"], function (_exports, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let RouteTableRowComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed('args.route.isLate', 'args.route.dispatchEventState.highlight', 'settings.events.tripLate.highlight'), _dec4 = Ember.computed('args.route.delivered'), _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = (_temp = class RouteTableRowComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "settings", _descriptor2, this);
    }

    get isLate() {
      return this.settings.events.tripLate.highlight && this.args.route.isLate || this.args.route.dispatchEventState.highlight;
    }

    get isDelivered() {
      // if the feature is disabled in configs - always return true, otherwise return the actual value of the property
      return !_environment.default.APP.route.showDeliveredStatus || this.args.route.delivered;
    }

    tripClicked() {
      // Send clicked actions to route-table. If the route has trips, open a trip view (which includes the route),
      // otherwise open a plain route view.
      if (this.args.route.trips[0]) {
        this.args.tripClicked(this.store.peekRecord('trip', this.args.route.trips[0].id));
      } else {
        this.args.routeClicked(this.args.route);
      }
    }

    handleContextMenu(e) {
      this.args.reselectRow(e.ctrlKey, this.args.route);
    }

    ignoreMainClick(e) {
      e.stopPropagation();
    }

    handleAssignVehicle(vehicle, forced) {
      this.args.assignVehicle(this.args.route, vehicle, forced);
    }

    handleStartDispatch(failAction, duration, excludedVehicles) {
      this.args.startDispatch(this.args.route, failAction, duration, excludedVehicles);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "settings", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isLate", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "isLate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isDelivered", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "isDelivered"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "tripClicked", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "tripClicked"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleContextMenu", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "handleContextMenu"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "ignoreMainClick", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "ignoreMainClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleAssignVehicle", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "handleAssignVehicle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleStartDispatch", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "handleStartDispatch"), _class.prototype)), _class));
  _exports.default = RouteTableRowComponent;
});