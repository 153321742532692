define("commander/services/auth", ["exports", "commander/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    isAuthenticated: false,
    accessToken: undefined,

    /**
     * Configure our auth0 instance
     */
    auth0: Ember.computed(function () {
      return new auth0.WebAuth({
        // setting up the config file will be covered below
        domain: _environment.default.auth0.domain,
        // domain from auth0
        clientID: _environment.default.auth0.clientId,
        // clientId from auth0
        redirectUri: `${window.location.origin}${_environment.default.rootURL}`,
        audience: _environment.default.auth0.audience,
        responseType: 'token id_token',
        // connection: ['Username-Password-Authentication', 'google-oauth2'],
        scope: 'openid profile email user_metadata' // adding profile because we want username, given_name, etc

      });
    }),

    /**
     * Send a user over to the hosted auth0 login page
     */
    login() {
      this.get('auth0').authorize();
    },

    /**
     * When a user lands back on our application
     * Parse the hash and store user info
     */
    handleAuthentication() {
      return new Promise(resolve => {
        this.get('auth0').parseHash((err, authResult) => {
          if (err) {
            return false;
          }

          if (authResult && authResult.accessToken) {
            this.setUser(authResult.accessToken);
          }

          return resolve();
        });
      });
    },

    /**
     * Use the token to set our user
     */
    setUser(token) {
      this.accessToken = token;
      this.isAuthenticated = true; // once we have a token, we are able to go get the users information

      this.get('auth0').client.userInfo(token, (err, profile) => this.set('user', profile));
    },

    /**
     * Check if we are authenticated using the auth0 library's checkSession
     */
    checkLogin() {
      return new Promise(resolve => {
        this.get('auth0').checkSession({}, (err, authResult) => {
          if (err) {
            resolve(false);
          } else {
            this.setUser(authResult.accessToken);
            resolve(true);
          }
        });
      });
    },

    /**
     * Get rid of everything in sessionStorage that identifies this user
     */
    logout() {
      this.get('auth0').logout({
        clientId: _environment.default.auth0.clientId,
        returnTo: `${window.location.origin}${_environment.default.rootURL}`
      });
    }

  });

  _exports.default = _default;
});