define("commander/pods/components/map-vehicle/component", ["exports", "@glimmer/component", "@fortawesome/fontawesome-svg-core", "@fortawesome/pro-solid-svg-icons"], function (_exports, _component, _fontawesomeSvgCore, _proSolidSvgIcons) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function createVehicleIcon(vehicleNumber, busy, selected) {
    return L.divIcon({
      className: `vehicleMarkerIcon ${selected ? ' highlight' : ''}`,
      iconAnchor: [12, 41],
      popupAnchor: [0, -38],
      html: `<span class="vehicle-number">${vehicleNumber}</span><span class="icon-wrap ${busy ? 'busy' : 'free'}">${(0, _fontawesomeSvgCore.icon)(_proSolidSvgIcons.faTaxi).html[0]}</span>`
    });
  }

  let MapVehicle = (_dec = Ember.computed('args.vehicle.{vehicleNumber,routeId}', 'args.selected'), (_class = class MapVehicle extends _component.default {
    get vehicleMarker() {
      return createVehicleIcon(this.args.vehicle.vehicleNumber, !!this.args.vehicle.routeId, this.args.selected);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "vehicleMarker", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "vehicleMarker"), _class.prototype)), _class));
  _exports.default = MapVehicle;
});