define("commander/models/route", ["exports", "@ember-data/model", "ember-api-actions", "commander/utils/route", "commander/config/environment"], function (_exports, _model, _emberApiActions, _route, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    settings: Ember.inject.service(),
    uiNotification: Ember.inject.service(),
    qpService: Ember.inject.service(),
    i18n: Ember.inject.service(),
    typeName: 'route',
    vehicleId: (0, _model.attr)('string'),
    vehicleNumber: (0, _model.attr)('string'),
    startTime: (0, _model.attr)('string'),
    endTime: (0, _model.attr)('string'),
    trips: (0, _model.attr)(),
    metrics: (0, _model.attr)(),
    externalId: (0, _model.attr)('string'),
    price: (0, _model.attr)(),
    type: (0, _model.attr)(),
    delivered: (0, _model.attr)('boolean'),
    dispatchStatus: null,
    dispatchVehicle: null,
    selected: false,
    // make it computed so each instance of the class has its own set of flags
    // 'notify' flag defines if there was a notification regarding the event. Set to 'false' by default to prevent notifications from appearing during init
    dispatchEventState: Ember.computed(() => ({
      highlight: false,
      notify: false
    })),
    stops: (0, _model.attr)(),
    dispatchStrategyIncludedVehicles: Ember.computed('trips.@each.id', 'trips.@each.dispatchStrategy', function () {
      return this.trips.map(i => ((i.dispatchStrategy || {}).params || {}).includedVehicles || []).flat();
    }),
    startTimeLocal: Ember.computed('startTime', function () {
      return new Date(this.startTime).toLocaleTimeString();
    }),
    endTimeLocal: Ember.computed('endTime', function () {
      return new Date(this.endTime).toLocaleTimeString();
    }),
    path: Ember.computed('stops', function () {
      return this.stops.reduce((prev, curr) => curr.gpath ? [...prev, ...polyline.decode(curr.gpath)] : prev, []);
    }),
    firstLocation: Ember.computed.reads('stops.firstObject'),
    lastLocation: Ember.computed.reads('stops.lastObject'),
    waypointLocations: Ember.computed('stops.@each.id', 'stops.@each.ordinal', function () {
      const stops = this.stops;
      return stops.slice(1, stops.get('length') - 1);
    }),
    startAddress: Ember.computed('firstLocation', 'firstLocation.location', function () {
      const location = (this.firstLocation || {}).location || {};
      return _route.default.formattedAddress(location, false) || '';
    }),
    startMunicipality: Ember.computed('firstLocation', 'firstLocation.location', function () {
      const location = (this.firstLocation || {}).location || {};
      return location.city || '';
    }),
    startAddressFull: Ember.computed('firstLocation', 'firstLocation.location', function () {
      const location = (this.firstLocation || {}).location || {};
      return _route.default.formattedAddress(location) || '';
    }),
    endAddress: Ember.computed('lastLocation', 'lastLocation.location', function () {
      const location = (this.lastLocation || {}).location || {};
      return _route.default.formattedAddress(location, false) || '';
    }),
    endMunicipality: Ember.computed('lastLocation', 'lastLocation.location', function () {
      const location = (this.lastLocation || {}).location || {};
      return location.city || '';
    }),
    endAddressFull: Ember.computed('lastLocation', 'lastLocation.location', function () {
      const location = (this.lastLocation || {}).location || {};
      return _route.default.formattedAddress(location) || '';
    }),
    startStopTime: Ember.computed.reads('firstLocation.plannedTime'),
    startStopDifferenceMs: Ember.computed('startStopTime', 'firstLocation.eta', 'firstLocation.completion.timestamp', function () {
      return _route.default.calculateStopDelay(this.get('firstLocation'));
    }),
    endStopTime: Ember.computed.reads('lastLocation.plannedTime'),
    endStopDifferenceMs: Ember.computed('endStopTime', 'lastLocation.eta', 'lastLocation.completion.timestamp', function () {
      return _route.default.calculateStopDelay(this.get('lastLocation'));
    }),
    // this should be watching completion statuses too...
    secondsLate: Ember.computed('stops.@each.eta', 'stops.@each.plannedTime', function () {
      return _route.default.secondsLate(this.stops);
    }),
    isLate: Ember.computed('secondsLate', function () {
      return this.secondsLate > _route.default.TRIP_LATE_THRESHOLD;
    }),
    noVehicle: Ember.computed('vehicleNumber', function () {
      return !this.vehicleNumber;
    }),
    isAsap: Ember.computed('trips.@each.labels', function () {
      return this.trips.some(trip => trip.labels && trip.labels.includes('asap'));
    }),
    isSOS: Ember.computed('trips.@each.labels', function () {
      return this.trips.some(trip => trip.labels && trip.labels.includes('sos'));
    }),
    dispatchEventStateObserver: Ember.observer('vehicleNumber', 'dispatchStatus', 'settings.events.dispatchFail.highlight', function () {
      // check if we have a `dispatchFail` setting. If not - set def values for the corresponding properties and exit the function.
      if (!this.get('settings.events.dispatchFail')) {
        this.set('dispatchEventState.highlight', false);
        this.set('dispatchEventState.notify', false);
        this.set('dispatchStatus', null);
        this.set('dispatchVehicle', null);
        return;
      } // TODO: somewhere here we need to implement a timer that will roll-back the dispatch status to null in a case, when we didn't receive any of 'failed', 'paused', 'accepted' states
      // ignore all dispatch events if the route already has an assigned vehicle


      if (this.vehicleNumber) {
        if (this.dispatchStatus !== _route.default.DISPATCH_STATUSES.accepted.id) {
          this.set('dispatchStatus', _route.default.DISPATCH_STATUSES.accepted.id);
        }

        this.set('dispatchEventState.highlight', false);
        this.set('dispatchEventState.notify', false);
        return;
      }

      const isDispatchFail = this.dispatchStatus === _route.default.DISPATCH_STATUSES.failed.id;
      this.set('dispatchEventState.highlight', this.get('settings.events.dispatchFail.highlight') && isDispatchFail);

      if (this.get('settings.events.dispatchFail.notify')) {
        if (isDispatchFail && this.get('dispatchEventState.notify')) {
          this.uiNotification.showDesktopNotification(this.i18n.t('notification.dispatchFail', {
            routeId: this.id
          }), () => {
            const trip = this.get('trips.firstObject');

            if (trip) {
              this.qpService.setQP('trip', trip.id);
            }

            window.focus();
          });
        }

        this.set('dispatchEventState.notify', this.dispatchStatus !== _route.default.DISPATCH_STATUSES.failed.id);
      } else {
        this.set('dispatchEventState.notify', false);
      }
    }),

    init() {
      this._super(...arguments); // trigger the observer to set the initial states


      this.dispatchEventStateObserver();
    },

    isStarted: Ember.computed('stops.@each.completion'
    /*{completion,completion.status}*/
    , function () {
      return this.stops.some(i => i.completion && i.completion.status !== _route.default.STOP_STATES.planned);
    }),
    routeStatus: Ember.computed('firstLocation.completion.status', 'lastLocation.completion.status', 'dispatchStatus', function () {
      if (this.lastLocation.completion && this.lastLocation.completion.status === _route.default.STOP_STATES.performed) {
        return 'completed';
      }

      if (this.firstLocation.completion && this.firstLocation.completion.status === _route.default.STOP_STATES.performed) {
        return 'inProgress';
      }

      return this.dispatchStatus;
    }),
    // only returns capacities defined in ENV conf, not all trip capacities
    capacities: Ember.computed('trips.@each.id', function () {
      const capacities = {};
      this.trips.forEach(trip => {
        (trip.capacity || []). // ignore capacities that are not defined in the environment configuration
        filter(i => _environment.default.APP.capacities[i.unitId]).forEach(i => {
          if (!capacities[i.unitId]) {
            capacities[i.unitId] = { ...i,
              ..._environment.default.APP.capacities[i.unitId]
            };
          } else {
            capacities[i.unitId].count += i.count;
          }
        });
      });
      return capacities;
    }),
    // returns all capacities contained in trip capacities
    // format {[capacity id]: {...capacity properties, env definition props if found, count: total count from trips}}
    allCapacities: Ember.computed('trips.@each.id', function () {
      const capacities = {};
      this.trips.forEach(trip => {
        (trip.capacity || []).forEach(i => {
          if (!capacities[i.unitId]) {
            capacities[i.unitId] = { ...i,
              ..._environment.default.APP.capacities[i.unitId]
            };
          } else {
            capacities[i.unitId].count += i.count;
          }
        });
      });
      return capacities;
    }),
    attributes: Ember.computed('trips.@each.id', function () {
      const attributes = {};
      this.trips.forEach(trip => {
        (trip.attributes || []).forEach(i => attributes[i.id] = { ...i
        });
      });
      return attributes;
    }),
    attributeList: Ember.computed('trips.@each.id', function () {
      const attrs = this.get('attributes');
      return Object.values(attrs);
    }),
    contract: Ember.computed('trips.@each.id', function () {
      if (this.trips.length > 0) {
        return this.trips[0].contract;
      }

      return null;
    }),

    /**
     * Is route started i.e. already picked someone and route unfinished.
     */
    isOngoing() {
      const isStarted = this.isStarted;
      let isFinished = false;

      if (isStarted) {
        const lastStop = this.stops.get('lastObject');

        if (lastStop && lastStop.completion) {
          isFinished = lastStop.completion.status === _route.default.STOP_STATES.performed || lastStop.completion.status === _route.default.STOP_STATES.noShow;
        }
      }

      return isStarted && !isFinished;
    },

    // -- helpers
    getTripPath(tripjson) {
      const stops = this.stops;
      const pickord = tripjson.stop.pick.ordinal; // there might be no drop in the ride - set the ordinal to infinity so that all the subsequent stops are included into the trip

      const dropord = tripjson.stop.drop ? tripjson.stop.drop.ordinal : Infinity;
      const tripstops = stops.filter(s => s.ordinal >= pickord && s.ordinal <= dropord);
      (true && !(tripstops.length >= 2) && Ember.assert(`trip ${tripjson.id} has ${tripstops.length} stops`, tripstops.length >= 2));
      const firststop = tripstops.shift();
      const pathtofirst = firststop.gpath ? polyline.decode(firststop.gpath) : [];
      (true && !(pathtofirst.length > 0) && Ember.assert(`stop ${firststop.id} doesn't have path`, pathtofirst.length > 0));
      return tripstops.reduce((prev, curr) => curr.gpath ? [...prev, ...polyline.decode(curr.gpath)] : prev, [pathtofirst[pathtofirst.length - 1]]);
    },

    setDispatchStatus(dispatchStatus, vehicleNumber, suppressNotification) {
      try {
        // make sure that our dispatch status exists
        dispatchStatus = _route.default.DISPATCH_STATUSES[dispatchStatus].id;
        this.set('dispatchStatus', dispatchStatus);
        this.set('dispatchVehicle', dispatchStatus === _route.default.DISPATCH_STATUSES.offering.id ? vehicleNumber : null);

        if (suppressNotification) {
          this.set('dispatchEventState.notify', false);
        }
      } catch (e) {
        console.log(e);
      }
    },

    // ember-api-actions start here
    assignVehicle: (0, _emberApiActions.memberAction)({
      path: 'vehicle',
      type: 'put'
    }),
    unassignVehicle: (0, _emberApiActions.memberAction)({
      path: 'vehicle',
      type: 'delete'
    }),
    getDispatchStatuses: (0, _emberApiActions.collectionAction)({
      path: 'dispatchStatuses',
      type: 'get'
    }),
    getOffers: (0, _emberApiActions.memberAction)({
      path: 'offers',
      type: 'get'
    }),
    setDispatchStrategy: (0, _emberApiActions.memberAction)({
      path: 'dispatch-strategy',
      type: 'put'
    }),
    getSuitableVehicles: (0, _emberApiActions.memberAction)({
      path: 'suitable-vehicles',
      type: 'get'
    }),
    stopPerform: (0, _emberApiActions.memberAction)({
      path: 'performs',
      type: 'post'
    })
  });

  _exports.default = _default;
});