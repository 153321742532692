define("commander/models/message", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    typeName: 'message',
    // for local usage
    vehicles: (0, _model.attr)(),
    expiration: (0, _model.attr)('date'),
    message: (0, _model.attr)('string'),
    sendTime: (0, _model.attr)('date') // used in fetching, not in sending

  });

  _exports.default = _default;
});