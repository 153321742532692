define("commander/pods/components/shift-row/component", ["exports", "jquery", "commander/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_BREAK_DURATION = _environment.default.APP.breakDuration;

  function breakToRaw(br) {
    const formatTime = d => d ? d.toISOString() : undefined;

    return {
      id: br.get('id'),
      duration: br.get('duration'),
      vehicleNumber: br.get('vehicleNumber'),
      periodStart: formatTime(br.get('periodStart')),
      periodEnd: formatTime(br.get('periodEnd')),
      plannedStart: formatTime(br.get('plannedStart')),
      plannedEnd: formatTime(br.get('plannedEnd')),
      estimatedStart: formatTime(br.get('estimatedStart')),
      estimatedEnd: formatTime(br.get('estimatedEnd'))
    };
  }
  /**
   * @param {Date} shiftStart - JS Date.
   * @param {Date} shiftEnd - JS Date.
   * @param periodDuration - in seconds.
   */


  function calcBreakPeriod(shiftStart, shiftEnd, periodDuration) {
    shiftStart = shiftStart.getTime();
    shiftEnd = shiftEnd.getTime();
    periodDuration = periodDuration * 1000;
    const shiftDuration = shiftEnd - shiftStart;
    const offset = (shiftDuration - periodDuration) / 2;
    return {
      periodStart: new Date(shiftStart + offset),
      periodEnd: new Date(shiftEnd - offset)
    };
  }

  function copyBreak(source, timediff) {
    return {
      duration: source.duration,
      periodStart: moment(source.periodStart).add(timediff, 'days').format(),
      periodEnd: moment(source.periodEnd).add(timediff, 'days').format()
    };
  }

  function copyShiftToAnotherDay(source, target, date) {
    const start = moment(source.get('startTime'));
    const end = moment(source.get('endTime'));
    date.hour(start.hour());
    date.minute(start.minute());
    const diff = date.diff(start, 'days');
    start.add(diff, 'days');
    end.add(diff, 'days');
    target.set('startTime', start.toDate());
    target.set('endTime', end.toDate());
    target.set('vehicleNumber', source.get('vehicleNumber'));

    if (source.get('breaks.length') > 0) {
      target.set('breaks', source.get('breaks').map(br => copyBreak(br, diff)));
    }

    return target;
  }

  function copyShiftToAnotherVehicle(source, target, vehicleNumber) {
    target.set('startTime', source.get('startTime'));
    target.set('endTime', source.get('endTime'));
    target.set('vehicleNumber', vehicleNumber);

    if (source.get('breaks.length') > 0) {
      target.set('breaks', source.get('breaks').map(br => copyBreak(br, 0)));
    }

    return target;
  }

  var _default = Ember.Component.extend({
    classNameBindings: ['isDriving', 'isNew:is-new'],
    tagName: 'tr',
    hover: false,
    isShowingRemoveConfirm: false,
    editVehicle: false,
    isEditing: false,
    isSaving: false,
    isDriving: false,
    notifications: Ember.inject.service(),
    i18n: Ember.inject.service(),
    store: Ember.inject.service(),
    settings: Ember.inject.service(),
    isDrivingWatch: Ember.observer('shift.isDriving', function () {
      const isDriving = this.get('shift.isDriving');
      this.set('isDriving', isDriving);
    }),
    isNew: Ember.computed.reads('shift.isNew', function () {
      return this.shift.isNew;
    }),

    init() {
      this._super(...arguments);

      this.set('isDriving', this.get('shift.isDriving'));
    },

    didRender() {
      if (this.isEditing) {
        (0, _jquery.default)('.datetimepicker-input').on('click', e => {
          Ember.run.bind(this, e.stopPropagation);
        });
      }
    },

    willDestroyElement() {
      this._super(...arguments);

      (0, _jquery.default)('.datetimepicker-input').off('click');
      this.element.removeEventListener('mouseenter', this.handleMouseEnter);
      this.element.removeEventListener('mouseleave', this.handleMouseLeave);
    },

    _shiftUpdated() {
      if (this.get('shift.isNew')) {
        if (!this.shift.vehicleNumber) {
          this.startEdit();
        }
      } else {
        // why do i have to do this?? #WTF
        //     for newly copied shifts break id is undefined even though it's
        //     clearly present if inspect it in shift-list mixin
        this.get('shift.breaks').forEach(br => {
          if (!br.id) {
            br.id = br.breakId;
          }
        });
      }
    },

    didInsertElement() {
      this._shiftUpdated();

      this._super(...arguments);

      this.element.addEventListener('mouseenter', this.handleMouseEnter);
      this.element.addEventListener('mouseleave', this.handleMouseLeave);
    },

    click(e) {
      if (!this.isEditing) {
        this.sendAction('vehicleClicked', this.vehicleNumber);
        e.preventDefault();
      }
    },

    actions: {
      handleMouseEnter()
      /*e*/
      {
        this.set('hover', true);
      },

      handleMouseLeave()
      /*e*/
      {
        this.set('hover', false);
      },

      editShift(e) {
        this.startEdit();
        e.stopPropagation();
      },

      cancelEdit(e) {
        this.toggleEdit(false);

        if (this.get('shift.isNew')) {
          this.sendAction('cancelNew', this.shift);
        }

        e.stopPropagation();
      },

      saveEdit(e) {
        const backupStart = this.get('shift.startTime');
        const backupEnd = this.get('shift.endTime');
        const inputStart = this.shiftStart;
        const inputEnd = this.shiftEnd;
        inputStart.setSeconds(0, 0);
        inputEnd.setSeconds(0, 0);
        const shift = this.shift;
        shift.set('startTime', inputStart);
        shift.set('endTime', inputEnd);
        shift.set('vehicleNumber', this.vehicleNumber);
        this.toggleEdit(false);
        this.set('isSaving', true);
        const isNew = shift.get('isNew');
        shift.save().then(() => {
          this.set('isSaving', false);

          if (isNew) {
            this.autoAddBreaks(shift);
          }
        }, error => {
          this.set('isSaving', false);
          this.toggleEdit(true);
          this.set('editVehicle', true);
          this.errorPopup(isNew ? 'add' : 'update', error, true, {
            startTime: shift.get('startTimeLocal'),
            endTime: shift.get('endTimeLocal'),
            vehicle: shift.get('vehicleNumber')
          });
          shift.set('startTime', backupStart);
          shift.set('endTime', backupEnd);
        });
        e.stopPropagation();
      },

      removeShift(e) {
        this.set('isShowingRemoveConfirm', true);
        e.stopPropagation();
      },

      newShiftCopy(e) {
        const store = this.store;
        this.shift.reload().then(source => {
          const shift = copyShiftToAnotherVehicle(source, store.createRecord('shift'));
          this.sendAction('newShiftCopy', shift);
        });
        e.stopPropagation();
      },

      stopEventProp(e) {
        e.stopPropagation();
      },

      copyShift(dates) {
        const addBreakToStore = (br, id, store) => {
          // const id = `${br.id}`;
          delete br.id;
          store.pushPayload('break', {
            data: {
              id,
              type: 'break',
              attributes: br
            }
          });
        };

        const processRawBreaks = (breaks, store) => {
          breaks.forEach(br => {
            const id = `${br.breakId}`;
            addBreakToStore(br, id, store);
          });
        };

        const store = this.store;
        this.shift.reload().then(source => {
          this.set('shift', source); // create copy records with new date

          const records = dates.map(d => copyShiftToAnotherDay(source, store.createRecord('shift'), d)); // create promises for saving all new copies

          records.map(r => () => r.save()).reduce((p, savefun, i) => {
            return p.then(savefun).then(shift => {
              this.sendAction('shiftAdded', shift);
              processRawBreaks(shift.get('breaks'), store);
            }).catch(error => {
              const startTime = records[i].get('startTimeLocal');
              const endTime = records[i].get('endTimeLocal');
              const vehicle = source.get('vehicleNumber');
              this.errorPopup('add', error, false, {
                startTime,
                endTime,
                vehicle
              });
            });
          }, Ember.RSVP.resolve());
        }).catch(err => {
          console.error('unable to reload source shift', err);
          this.errorPopup('copy', 'loadFailed');
        });
      },

      hideModal() {
        this.set('isShowingRemoveConfirm', false);
      },

      removeConfirmed() {
        this.set('isShowingRemoveConfirm', false);
        this.sendAction('removeShift', this.shift);
      },

      onShiftStartSelect(val) {
        this.set('shiftStart', val);
      },

      onShiftEndSelect(val) {
        this.set('shiftEnd', val);
      }

    },

    calculatePosition(trigger, content) {
      let {
        top,
        left,
        width,
        height
      } = trigger.getBoundingClientRect();
      let {
        height: contentHeight
      } = content.getBoundingClientRect();
      let style = {
        left: left + width + 20,
        top: top + window.pageYOffset + height / 2 - contentHeight / 2
      }; // make sure the popup doesn't go outside browser viewport

      if (style.top < 0) {
        style.top = 0;
      } else if (style.top + contentHeight > window.innerHeight) {
        style.top = window.innerHeight - contentHeight;
      }

      return {
        style
      };
    },

    toggleEdit(edit) {
      this.set('isEditing', edit);
      this.set('editVehicle', edit && !this.get('shift.vehicleNumber'));
      this.set('shiftStart', this.get('shift.startTime'));
      this.set('shiftEnd', this.get('shift.endTime'));
    },

    startEdit() {
      this.toggleEdit(true);
    },

    autoAddBreaks(shift) {
      if (!this.settings.getGenerateBreak()) {
        return;
      }

      const periodDuration = DEFAULT_BREAK_DURATION * 3;

      if (shift.get('duration') < periodDuration) {
        console.log('too short shift for adding break automatically');
        return;
      }

      const {
        periodStart,
        periodEnd
      } = calcBreakPeriod(shift.get('startTime'), shift.get('endTime'), periodDuration);
      const br = this.store.createRecord('break', {
        vehicleNumber: shift.get('vehicleNumber'),
        duration: DEFAULT_BREAK_DURATION,
        periodStart,
        periodEnd
      });
      br.save().then(newbr => {
        shift.set('breaks', [breakToRaw(newbr)]);
      }).catch(err => {
        console.error(err);
        this.errorPopup('addBreak', 'unknown');
      });
    },

    errorPopup(operation, error, autoClear = true, options = {}) {
      const getErrorMsg = r => {
        let code = r.toString();

        if (r.errors && r.errors[0] && r.errors[0].detail) {
          code = `${r.errors[0].detail}`;

          try {
            code = JSON.parse(code);
            code = code.message ? code.message : code.code;
          } catch (e) {
            // sometimes errors from server are in form <code>:<parameters> and sometimes we just have <code>
            code = code ? code.split(':')[0] : '';
          }
        }

        return code;
      };

      const errorMsg = getErrorMsg(error);
      this.notifications.error(this.i18n.t(`shiftList.errors.${operation}.${errorMsg}`, options), {
        cssClasses: 'commander-notification',
        autoClear,
        clearDuration: 5000
      });
    }

  });

  _exports.default = _default;
});