define("commander/serializers/application", ["exports", "@ember-data/serializer/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    /**
     * JSON API adapter expects properties to be in kebab-case. We don't want that.
     */
    keyForAttribute(key) {
      return key;
    },

    /**
     * JSON API adapter expects properties to be in kebab-case. We don't want that.
     */
    keyForRelationship(rawKey) {
      return rawKey;
    }

  });

  _exports.default = _default;
});