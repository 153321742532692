define("commander/pods/components/trip-status-icon/component", ["exports", "@glimmer/component", "commander/utils/route"], function (_exports, _component, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const STATUS_ICONS = {
    noShow: 'minus-circle',
    onBoard: 'taxi',
    finished: 'check-circle',
    arrivingPickup: 'map-marker-alt',
    notStarted: ''
  };

  function selectState(states, key) {
    return states[key] || STATUS_ICONS.notStarted;
  }

  const TRIP_ICONS = {};
  const PICK_ICONS = {};
  const DROP_ICONS = {};
  const FLEX_ICONS = {
    [_route.default.TRIP_STATES.noShow]: STATUS_ICONS.noShow,
    [_route.default.TRIP_STATES.finished]: STATUS_ICONS.finished,
    [_route.default.TRIP_STATES.arrivingPickup]: STATUS_ICONS.arrivingPickup,
    [_route.default.TRIP_STATES.notStarted]: STATUS_ICONS.notStarted
  };
  TRIP_ICONS[_route.default.TRIP_STATES.noShow] = STATUS_ICONS.noShow;
  TRIP_ICONS[_route.default.TRIP_STATES.finished] = STATUS_ICONS.finished;
  TRIP_ICONS[_route.default.TRIP_STATES.onBoard] = STATUS_ICONS.onBoard;
  TRIP_ICONS[_route.default.TRIP_STATES.arrivingPickup] = STATUS_ICONS.arrivingPickup;
  TRIP_ICONS[_route.default.TRIP_STATES.notStarted] = STATUS_ICONS.notStarted;
  PICK_ICONS[_route.default.TRIP_STATES.noShow] = STATUS_ICONS.noShow;
  PICK_ICONS[_route.default.TRIP_STATES.finished] = STATUS_ICONS.finished;
  PICK_ICONS[_route.default.TRIP_STATES.onBoard] = STATUS_ICONS.finished;
  PICK_ICONS[_route.default.TRIP_STATES.arrivingPickup] = STATUS_ICONS.arrivingPickup;
  PICK_ICONS[_route.default.TRIP_STATES.notStarted] = STATUS_ICONS.notStarted;
  DROP_ICONS[_route.default.TRIP_STATES.noShow] = STATUS_ICONS.noShow;
  DROP_ICONS[_route.default.TRIP_STATES.finished] = STATUS_ICONS.finished;
  DROP_ICONS[_route.default.TRIP_STATES.onBoard] = STATUS_ICONS.onBoard;
  DROP_ICONS[_route.default.TRIP_STATES.arrivingPickup] = STATUS_ICONS.notStarted;
  DROP_ICONS[_route.default.TRIP_STATES.notStarted] = STATUS_ICONS.notStarted;
  let TripStatusIcon = (_dec = Ember.inject.service, _dec2 = Ember.computed('statusIcon', 'args.trip.route.dispatchStatus'), (_class = (_temp = class TripStatusIcon extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "i18n", _descriptor, this);
    }

    get statusText() {
      const icon = this.statusIcon;
      const dispatchStatus = this.args.trip ? this.args.trip.get('route.dispatchStatus') : undefined;
      let text = '';

      if (icon === STATUS_ICONS.noShow) {
        text = this.i18n.t('tripList.tripStatus.noShow');
      } else if (icon === STATUS_ICONS.finished) {
        text = this.i18n.t('tripList.tripStatus.finished');
      } else if (icon === STATUS_ICONS.onBoard) {
        text = this.i18n.t('tripList.tripStatus.onBoard');
      } else if (icon === STATUS_ICONS.arrivingPickup) {
        text = this.i18n.t('tripList.tripStatus.arrivingPickup');
      } else if (icon === STATUS_ICONS.notStarted) {
        text = this.i18n.t('tripList.tripStatus.notStarted');
      } else if (Object.prototype.hasOwnProperty.call(_route.default.DISPATCH_STATUSES, dispatchStatus)) {
        text = this.i18n.t(`dispatchStatus.${_route.default.DISPATCH_STATUSES[dispatchStatus].id}`);
      }

      return text;
    }

    get statusIcon() {
      // Check that trip is provided, since it is possible to only provide selectedStop without
      // trip in the case of flex stops.
      const tripStatus = this.args.trip ? this.args.trip.get('tripStatus') : undefined;
      const dispatchStatus = this.args.trip ? this.args.trip.get('route.dispatchStatus') : undefined;
      const selectedStop = this.args.selectedStop;
      let status = STATUS_ICONS.notStarted;

      if (!selectedStop) {
        status = selectState(TRIP_ICONS, tripStatus);
      } else if (selectedStop.type === 'pick') {
        status = selectState(PICK_ICONS, tripStatus);
      } else if (selectedStop.type === 'drop') {
        status = selectState(DROP_ICONS, tripStatus);
      } else if (selectedStop.type === 'flex') {
        const stopStatus = String(_route.default.completionToTripStatus(selectedStop.completion.status));
        status = selectState(FLEX_ICONS, stopStatus);
      } // try to set dispatch state ONLY if the trip state is 'notStarted'


      if (status === STATUS_ICONS.notStarted && Object.prototype.hasOwnProperty.call(_route.default.DISPATCH_STATUSES, dispatchStatus)) {
        status = _route.default.DISPATCH_STATUSES[dispatchStatus].icon;
      }

      return status;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "i18n", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "statusText", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "statusText"), _class.prototype)), _class));
  _exports.default = TripStatusIcon;
});