define("commander/pods/index/shifts/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    dataFilter: Ember.inject.service('data-filter'),
    displayWindow: Ember.inject.service('display-window'),
    originalWindow: {},

    activate() {
      this.dataFilter.reloadShiftsOnly = true; // If display-window service still uses default values, we need to get the window
      // from an event, which should be fired after the service initializes.
      // This situation occurs when the browser window is reloaded with this route open.

      if (this.displayWindow.realtimeWindow && this.displayWindow.realtimeWindow.WINDOW_START) {
        this.displayWindow.addRangeObserver('shift-route', this.setOriginalWindowFromEvent.bind(this));
      } else {
        this.originalWindow.fixed = this.displayWindow.fixedWindow;
        this.originalWindow.realtime = this.displayWindow.realtimeWindow;
        this.originalWindow.label = this.displayWindow.displayLabel;
      }
    },

    deactivate() {
      this.dataFilter.reloadShiftsOnly = false;
      const label = this.originalWindow.label;

      if (this.originalWindow.fixed) {
        const {
          start,
          end
        } = this.originalWindow.fixed;
        this.displayWindow.setFixedWindow(start, end, label);
      } else if (this.originalWindow.realtime) {
        const {
          start,
          end
        } = this.originalWindow.realtime;
        this.displayWindow.setRealtimeWindow(start / 60000, end / 60000, label);
      }
    },

    setOriginalWindowFromEvent(windowData) {
      this.originalWindow.fixed = this.displayWindow.fixedWindow;
      this.originalWindow.realtime = this.displayWindow.realtimeWindow;
      this.originalWindow.label = windowData.label;
      this.displayWindow.removeRangeObserver('shift-route');
    },

    close() {
      this.transitionTo('index');
    },

    actions: {
      close() {
        this.transitionTo('index');
      }

    }
  });

  _exports.default = _default;
});