define("commander/pods/components/detail-property-row/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { computed } from '@ember/object';
  class DetailPropertyRow extends _component.default {
    //@computed('args.value')
    get isHidden() {
      return Ember.isEmpty(this.args.value);
    }

  }

  _exports.default = DetailPropertyRow;
});