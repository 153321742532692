define("commander/pods/components/message-table/component", ["exports", "commander/mixins/data-sorter"], function (_exports, _dataSorter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dataSorter.default, {
    classNames: ['growing-table-div'],
    toBeSorted: 'data',
    sortedResult: 'sorted',
    initialSort: {
      by: 'id',
      type: 'complex',
      reversed: false
    },

    init() {
      this._super(...arguments);

      this.set('_lastSort', this.initialSort);
      this.dataChange; // init observer
    },

    dataChange: Ember.observer('data.[]', 'data', 'data.messages.[]', function () {
      this.sortAgain();
    }),
    actions: {}
  });

  _exports.default = _default;
});