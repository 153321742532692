define("commander/pods/components/daterange-picker/component", ["exports", "jquery", "@fortawesome/fontawesome-svg-core"], function (_exports, _jquery, _fontawesomeSvgCore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function setDayBegin(dt) {
    let m = moment(dt);
    return m.startOf('day');
  }

  function setDayEnd(dt) {
    let m = moment(dt);
    return m.endOf('day');
  }

  var _default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    locale: Ember.inject.service(),
    dataUpdate: Ember.inject.service('data-update'),
    dataFilter: Ember.inject.service('data-filter'),
    displayWindowService: Ember.inject.service('display-window'),
    qpService: Ember.inject.service('qpService'),
    ranges: {},
    // label -> function to get the range
    initranges: {},
    // label -> initial range values to initialize the range picker
    liveranges: [],
    // label -> value of the relative display window in minutes [start, end]
    startDate: null,
    // set when fixed window is selected
    endDate: null,
    // set when fixed window is selected
    liveRange: null,
    // set when live window is selected
    searchLabel: null,
    // shortcut to i18n.t('datetime.ranges.search').string;
    selectedRangeLabel: '',
    // displayed string
    customRangeLabel: '',
    // displayed string
    rangeTitle: '',
    dateLimit: {
      // Limit daterange picker to allow only:
      months: 1
    },
    liveText: '',

    // For displaying 'Live' text in UI
    init() {
      let timeFrame = this.qpService.getQP('timeFrame'); // Initialize the range with the next30minutes range

      const [defaultLabel, defaultRange] = this.initRanges(timeFrame);
      this.updateSelection(defaultRange()[0], defaultRange()[1], defaultLabel);

      this._super(...arguments);

      this.locale; // Ember kludge: will make observer to be fired
    },

    initRanges(timeFrame) {
      const ranges = [['datetime.ranges.next30minutes', () => [moment().add(-20, 'minutes'), moment().add(30, 'minutes')]], ['datetime.ranges.next3hours', () => [moment().add(-20, 'minutes'), moment().add(180, 'minutes')]], ['datetime.ranges.today', () => [setDayBegin(), setDayEnd()]], ['datetime.ranges.yesterday', () => [setDayBegin().subtract(1, 'days'), setDayEnd().subtract(1, 'days')]], ['datetime.ranges.tomorrow', () => [setDayBegin().add(1, 'days'), setDayEnd().add(1, 'days')]], ['datetime.ranges.next2days', () => [setDayBegin(), setDayEnd().add(2, 'days')]]];
      this.set('ranges', ranges.reduce((acc, c) => {
        acc[c[0]] = c[1];
        return acc;
      }, {}));
      this.set('initranges', ranges.reduce((acc, c) => {
        acc[c[0]] = c[1]();
        return acc;
      }, {}));
      const liveranges = {};
      liveranges['datetime.ranges.next30minutes'] = [-20, 30];
      liveranges['datetime.ranges.next3hours'] = [-20, 180];
      let defLabel = 'datetime.ranges.next30minutes';
      let defRange = this.ranges[defLabel];

      if (timeFrame && timeFrame.match(/^\d+-\d+$/)) {
        // for time frames defined through the timestamps
        defLabel = 'datetime.ranges.search'; // all labels have corresponding ranges. However search option does not have one. Lets add it.

        defRange = () => [moment.unix(timeFrame.split('-').shift()), moment.unix(timeFrame.split('-').pop())];
      } else if (timeFrame && this.ranges[`datetime.ranges.${timeFrame}`]) {
        // for time frames defined by labels
        defLabel = `datetime.ranges.${timeFrame}`;
        defRange = this.ranges[defLabel];
      }

      this.set('liveranges', liveranges);
      this.set('searchLabel', 'datetime.ranges.search');
      this.set('defaultLabel', defLabel);
      this.set('selectedRangeLabel', this.defaultLabel);
      return [defLabel, defRange];
    },

    localeChange: Ember.observer('locale.change', function () {
      this.initDateRangePicker();
    }),

    didInsertElement() {
      this.initDateRangePicker();
    },

    calculateDuration() {
      if (this.startDate) {
        return this.endDate - this.startDate;
      } else {
        return (this.liveRange[1] - this.liveRange[0]) * 60000;
      }
    },

    isFixedRange(label) {
      return this.liveranges[label] === undefined;
    },

    getLiveRange(label) {
      return this.liveranges[label];
    },

    /**
     * event: callback for when user selects a new range
     *
     * @param {Moment|Number} start - absolute start time (moment) or relative start time (number)
     * @param {Moment|Number} end - absolute end time (moment) or relative end time (number)
     * @param {string} label - a label of the selected date-range
     */
    updateSelection(start, end, label) {
      let val = label && label !== 'datetime.ranges.search' ? label.split('.').pop() : `${start.format('X')}-${end.format('X')}`;
      this.qpService.setQP('timeFrame', val);
      this.updateSelectionData(start, end, label);
      this.updateSelectionUI(label);
    },

    updateSelectionData(start, end, label) {
      if (label && label !== this.searchLabel) {
        [start, end] = this.ranges[label]();
      }

      if (this.isFixedRange(label)) {
        this.set('startDate', start);
        this.set('endDate', end);
        this.set('liveRange', null);
        this.displayWindowService.setFixedWindow(this.startDate.toDate(), this.endDate.toDate());
      } else {
        [start, end] = this.getLiveRange(label);
        this.set('startDate', null);
        this.set('endDate', null);
        this.set('liveRange', [start, end]);
        this.displayWindowService.setRealtimeWindow(start, end);
      }
    },

    updateSelectionUI(label) {
      if (label && label !== this.searchLabel) {
        // user clicked one of the shortcuts
        this.set('selectedRangeLabel', label);
        this.set('customRangeLabel', '');
      } else {
        // user either clicked "search" or right/left arrows
        this.set('customRangeLabel', this.startDate.format('MMMM D, YYYY HH:mm') + ' - ' + this.endDate.format('MMMM D, YYYY HH:mm'));
        this.set('selectedRangeLabel', this.searchLabel);
      }

      if (label === 'datetime.ranges.next30minutes' || label === 'datetime.ranges.next3hours') {
        this.set('liveText', 'Live');
      } else {
        this.set('liveText', '');
      }
    },

    /**
     *
     * As following is called on init and for each locale change and daterange picker is not Ember component,
     * there are few jQuery operations for making daterange picker working on subsequent calls
     *
     **/
    initDateRangePicker() {
      const i18n = this.i18n;
      let showCalendar = false;
      const drp = (0, _jquery.default)('#daterange-picker'); // Remove old range labels from the daterangepicker dropdown template

      (0, _jquery.default)('#id-daterangepicker-dropdow-menu ul').remove();
      const currentLabel = this.selectedRangeLabel;
      const currentRange = this.ranges[currentLabel];
      drp.daterangepicker({
        label: this.rangeTitle,
        startDate: currentRange ? currentRange()[0] : this.startDate,
        endDate: currentRange ? currentRange()[1] : this.endDate,
        ranges: this.initranges,
        timePicker: true,
        linkedCalendars: false,
        applyClass: 'btn-outline-dark btn-block',
        cancelClass: 'btn-outline-secondary btn-block',
        showCustomRangeLabel: true,
        locale: {
          customRangeLabel: this.searchLabel,
          applyLabel: i18n.t('datetime.ranges.apply').string,
          cancelLabel: i18n.t('datetime.ranges.cancel').string
        },
        alwaysShowCalendars: false,
        dateLimit: this.dateLimit,
        timePicker24Hour: true,
        autoApply: true,
        template: (0, _jquery.default)('#id-daterangepicker-dropdow-menu')
      }, Ember.run.bind(this, (start, end, labelKey) => {
        let _labelKey = labelKey; // For some reason custom search does not return key but the translated value
        // -> following is just a workaround

        if (i18n.t(this.searchLabel).string === labelKey) {
          _labelKey = this.searchLabel;
        }

        this.updateSelection(start, end, _labelKey);
      }));
      const toBeTranslated = (0, _jquery.default)('#id-daterangepicker-dropdow-menu ul li'); // Dropdown selection list is now populated with key names -> translate keys to current lang

      toBeTranslated.each((idx, el) => (0, _jquery.default)(el).text(i18n.t(el.getAttribute('data-range-key')).string));
      drp.off('show.daterangepicker');
      drp.on('show.daterangepicker', Ember.run.bind(this, (ev, picker) => {
        picker.container.find('.calendar-buttons').on('click.calendar-buttons', 'button.applyBtn', _jquery.default.proxy(picker.clickApply, picker)).on('click.calendar-buttons', 'button.cancelBtn', Ember.run.bind(picker, () => {
          picker.clickCancel();
          picker.hideCalendars();
          showCalendar = false;
        }));
        const label = this.searchLabel;
        picker.container.find(`li[data-range-key="${label}"]`).on('click', Ember.run.bind(this, e => {
          if (showCalendar) {
            e.stopPropagation();
            picker.hideCalendars();
          }

          showCalendar = !showCalendar;
        }));

        _fontawesomeSvgCore.dom.i2svg({
          node: picker.container[0]
        });
      }));
      drp.off('hide.daterangepicker');
      drp.on('hide.daterangepicker', Ember.run.bind(this, (ev, picker) => {
        picker.container.find('.calendar-buttons').off('click.calendar-buttons', 'button.applyBtn').off('click.calendar-buttons', 'button.cancelBtn');
        const label = this.searchLabel;
        picker.container.find(`li[data-range-key="${label}"]`).off('click');
      }));
    },

    actions: {
      moveForward() {
        if (this.startDate) {
          const diff = this.calculateDuration();
          this.updateSelection(this.startDate.add(diff), this.endDate.add(diff), this.searchLabel);
        } else {
          const range = this.liveRange;
          this.updateSelection(moment().add(range[1], 'minutes'), moment().add(range[1] * 2, 'minutes'), this.searchLabel);
        }
      },

      moveBackward() {
        if (this.startDate) {
          const diff = this.calculateDuration();
          this.updateSelection(this.startDate.subtract(diff), this.endDate.subtract(diff), this.searchLabel);
        } else {
          const range = this.liveRange;
          this.updateSelection(moment().subtract(range[1] * 2, 'minutes'), moment().subtract(range[1], 'minutes'), this.searchLabel);
        }
      }

    }
  });

  _exports.default = _default;
});