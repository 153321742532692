define("commander/serializers/trip", ["exports", "commander/serializers/application", "commander/utils/utils"], function (_exports, _application, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeResponse(store, primaryModelClass, payload
    /*, id, requestType*/
    ) {
      payload.data.forEach(t => _utils.default.normalizeTrip(t.attributes, t.id));
      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});