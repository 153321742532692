define("commander/pods/components/vehicle-table/component", ["exports", "commander/abstract/items-filter"], function (_exports, _itemsFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let VehicleTable = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed, _dec5 = Ember.computed, _dec6 = Ember.computed, _dec7 = Ember.computed, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_class = (_temp = class VehicleTable extends _itemsFilter.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "dataFilter", _descriptor, this);

      _initializerDefineProperty(this, "mapService", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);
    }

    get columns() {
      return [{
        class: 'vinka-w10p',
        isSortable: true,
        name: '',
        valuePath: 'id',
        icon: 'hashtag'
      }, {
        class: 'vinka-w35p',
        isSortable: true,
        name: 'vehicleList.name',
        valuePath: 'name'
      }, {
        class: 'vinka-w35p',
        isSortable: false,
        name: 'vehicleList.seats'
      }, {
        class: 'vinka-w20p',
        isSortable: false,
        name: 'vehicleList.shift'
      }];
    }

    get capacityFilters() {
      const allCapacities = this.args.allCapacities || []; // prepare filters for capacities

      return allCapacities.reduce((all, cap) => {
        // create object that fits for filters list (key as capacity id)
        all[cap.id] = {
          id: cap.id,
          value: cap.id,
          name: cap.name,
          checked: false,
          icon: cap.icon
        };
        return all;
      }, {});
    }

    get attributeFilters() {
      const allAttributes = this.args.allAttributes || []; // prepare filters for attributes

      return allAttributes.reduce((all, filter) => {
        // create object that fits for filters list (key as property id)
        all[filter.id] = {
          id: filter.id,
          value: filter.id,
          name: filter.name,
          checked: false,
          icon: filter.icon
        };
        return all;
      }, {});
    }

    get sorts() {
      return [{
        valuePath: 'id',
        isAscending: true
      }];
    }

    onVehicleHover(vehicle) {
      this.mapService.setHoveredRoute(vehicle.getCurrentRoute());
    }

    onVehicleOut() {
      this.mapService.setHoveredRoute(null);
    }

    applyFilter() {
      super.applyFilter(...arguments);
      this.mapService.setFocusedVehicles(this.items.map(i => `${i.number}`));
    }

    dropFilter() {
      super.dropFilter(...arguments);
      this.mapService.setFocusedVehicles(this.items.map(i => `${i.number}`));
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dataFilter", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "mapService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "columns", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "columns"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "capacityFilters", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "capacityFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "attributeFilters", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "attributeFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sorts", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "sorts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onVehicleHover", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "onVehicleHover"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onVehicleOut", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "onVehicleOut"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "applyFilter", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "applyFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dropFilter", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "dropFilter"), _class.prototype)), _class));
  _exports.default = VehicleTable;
});