define("commander/abstract/items-filter", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const getCapacityIds = capacities => capacities.filter(i => i.count > 0).map(i => i.unitId);

  let ItemsFilter = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember.computed('appliedFilters.length', 'args.items'), _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = class ItemsFilter extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "filters", _descriptor, this);

      _initializerDefineProperty(this, "appliedFilters", _descriptor2, this);
    }

    get items() {
      console.debug('filters or data changed, refreshing items');

      if (!this.appliedFilters.length) {
        return this.args.items;
      } // this.appliedFilters containing object should have:
      //  - type - value should match property name of the object that is filtered
      //  - value - search value to compare for, can be an array


      const filtersObj = this.appliedFilters.reduce((a, i) => ({ ...a,
        [i.type]: [...(a[i.type] || []), i.value]
      }), {}); // iterate through each filter and check if an appropriate property equals to one of the values of the filter
      // item - item in array to be filtered (route/trip/vehicle/etc.)
      // name - key from applied appliedFilters list, generated above from original appliedFilters type
      // prop - value (or object) from the object filtered

      return this.args.items.filter(item => Object.keys(filtersObj).reduce( // test each filter against the item
      (a, name) => {
        const propFilters = filtersObj[name];
        const prop = item[name]; // value from object

        if (Array.isArray(prop)) {
          // for capacities it is nested array
          const ids = prop.map(arrayItem => Array.isArray(arrayItem) ? getCapacityIds(arrayItem) : arrayItem.id).flat();

          if (name === 'contract') {
            const lowercaseProp = prop.map(p => p.toLowerCase());
            return a && propFilters.some(f => lowercaseProp.some(i => i.includes(f.toLowerCase())));
          } else {
            return a && propFilters.every(f => ids.includes(+f)); // match each capacity search option
          }
        } else if (prop instanceof Object) {
          // filtered property is object
          return a && propFilters.some(f => prop[f]);
        } else {
          // string filtering
          return a && propFilters.some(f => `${prop}`.toLowerCase().includes(`${f}`.toLowerCase()));
        }
      }, true));
    }

    applyFilter(type, value) {
      if (typeof value === 'boolean') {
        this.filters[type] = value;

        if (value) {
          this.appliedFilters.push({
            type: type,
            label: '',
            value: true
          });
        } else {
          this.appliedFilters = this.appliedFilters.filter(i => i.type !== type);
        }
      } else if (this.filters[type] instanceof Object) {
        const index = this.appliedFilters.findIndex(i => `${i.type}` === `${type}` && `${i.value}` === `${value}`);

        if (index !== -1) {
          this.appliedFilters.splice(index, 1);
        } else {
          const filter = this.filters[type][value];
          filter.checked = !filter.checked;
          this.appliedFilters.push({
            type: type,
            value: filter.value,
            label: filter.name
          });
        }
      } else {
        // ignore, if the value is empty OR if the same filter is already applied
        if (value && !this.appliedFilters.some(i => i.type === type && i.value === value)) {
          this.appliedFilters.push({
            type: type,
            label: value,
            value: value
          });
        }

        this.filters[type] = null;
      }

      this.apply();
    }

    dropFilter(index) {
      const filter = this.appliedFilters[index];

      if (typeof filter.value === 'boolean') {
        this.filters[filter.type] = false;
      } else if (this.filters[filter.type] instanceof Object) {
        this.filters[filter.type][filter.value].checked = false;
      }

      this.appliedFilters.splice(index, 1);
      this.apply();
    }

    apply() {
      this.filters = { ...this.filters
      };
      this.appliedFilters = [...this.appliedFilters];
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "appliedFilters", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "items", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "items"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "applyFilter", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "applyFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dropFilter", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "dropFilter"), _class.prototype)), _class));
  _exports.default = ItemsFilter;
});