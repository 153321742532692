define("commander/pods/components/map-route/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t5Nnz7S0",
    "block": "{\"symbols\":[\"@route\"],\"statements\":[[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"map-route-points\",[],[[\"@route\",\"@weight\"],[[32,1],8]],null],[2,\"\\n\\n\"],[2,\"    \"],[8,\"circle-marker-layer\",[],[[\"@radius\",\"@color\",\"@fillOpacity\",\"@location\"],[14,\"green\",0.4,[32,1,[\"firstLocation\"]]]],null],[2,\"\\n    \"],[8,\"circle-marker-layer\",[],[[\"@radius\",\"@color\",\"@fillOpacity\",\"@stroke\",\"@location\"],[7,\"green\",1,false,[32,1,[\"firstLocation\"]]]],null],[2,\"\\n\\n\"],[2,\"    \"],[8,\"circle-marker-layer\",[],[[\"@radius\",\"@color\",\"@fillOpacity\",\"@location\"],[14,\"red\",0.4,[32,1,[\"lastLocation\"]]]],null],[2,\"\\n    \"],[8,\"circle-marker-layer\",[],[[\"@radius\",\"@color\",\"@fillOpacity\",\"@stroke\",\"@location\"],[7,\"red\",1,false,[32,1,[\"lastLocation\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "commander/pods/components/map-route/template.hbs"
    }
  });

  _exports.default = _default;
});