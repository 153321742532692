define("commander/helpers/local-time-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.localDateTimeString = localDateTimeString;
  _exports.localTimeDateString = localTimeDateString;
  _exports.default = void 0;

  function localDateTimeString(time) {
    return time ? moment(time).format('L LT') : '';
  }

  function localTimeDateString(time) {
    return time ? moment(time).format('LT L') : '';
  }

  var _default = Ember.Helper.helper(params => localDateTimeString(params[0]));

  _exports.default = _default;
});