define("commander/pods/components/shifts-table/component", ["exports", "commander/mixins/shift-list", "commander/mixins/data-sorter"], function (_exports, _shiftList, _dataSorter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // selector dropdown helper to convert vehicle pattern selectors to vehicle number array
  // may return doubles
  const searchTermsToVehicleNumbers = term => {
    // range returns array of numbers
    const range = (from, to = from) => from > to ? range(to, from) : from === to ? [from] : [from, ...range(from + 1, to)];

    let commaterms = term.split(',');
    const vehicleNbrs = commaterms.reduce((all, item) => {
      if (item.indexOf('-') > 0) {
        const interval = item.split('-');
        const numbers = [Number(interval[0]), Number(interval[1])];

        if (!isNaN(numbers[0]) && !isNaN(numbers[1])) {
          const vehs = range(...numbers);
          all.push(...vehs);
        }
      } else {
        const nbr = Number(item);

        if (nbr) {
          all.push(nbr);
        }
      }

      return all;
    }, []);
    return vehicleNbrs;
  };

  var _default = Ember.Component.extend(_shiftList.default, _dataSorter.default, {
    store: Ember.inject.service(),
    i18n: Ember.inject.service(),
    classNames: ['table-div'],
    toBeSorted: 'shifts',
    sortedResult: 'sorted',
    initialSort: {
      by: 'startTime',
      type: 'date',
      reversed: false
    },
    appliedFilters: Ember.A([]),
    // select values
    selectedVehicles: Ember.A([]),
    allVehiclesSelected: false,

    init() {
      this._super(...arguments);

      this.set('_lastSort', this.initialSort);
      this.set('shifts', this.store.peekAll('shift'));
    },

    didInsertElement() {
      this._super(...arguments);

      this.sortAgain();
    },

    viewShifts: Ember.computed('appliedFilters.[]', 'allVehiclesSelected', 'sorted', function () {
      if (!this.appliedFilters.length && !this.allVehiclesSelected) {
        return [];
      }

      return this.sorted;
    }),
    dataChange: Ember.observer('shifts.[]', function () {
      this.sortAgain();
    }),
    // ---- vehicle selector functions ----
    allVehicles: Ember.computed(function () {
      return this.store.peekAll('vehicle');
    }),

    // when search terms have updated -> re-form vehicle filters and call sorter
    applyNewFilters() {
      this.appliedFilters.clear();
      const starFound = this.selectedVehicles.includes('*'); // if star found as term -> show all vehicles

      if (starFound) {
        this.appliedFilters.clear();
        this.set('allVehiclesSelected', true);
      } else {
        this.set('allVehiclesSelected', false); // convert selected terms to unique vehicle array

        const vehicleNumbers = this.selectedVehicles.map(nbr => {
          return searchTermsToVehicleNumbers(nbr);
        }).flat();
        const searchTerms = [...new Set(vehicleNumbers)].map(nbr => {
          return {
            type: 'vehicleNumber',
            value: nbr
          };
        });
        this.appliedFilters.setObjects(searchTerms); // add term for shifts without vehicle

        this.appliedFilters.pushObject({
          type: 'vehicleNumber',
          value: 'undefined'
        });
      }

      this.sortAgain();
    },

    actions: {
      cancelNew(shift) {
        shift.deleteRecord();
      },

      vehicleClicked(vehicleNumber) {
        this.sendAction('vehicleClicked', vehicleNumber);
      },

      // When a new blank shift is created
      newShift() {
        // implemented in mixin
        this._super();

        this.applyNewFilters();
      },

      // When a shift is copied from calendar
      shiftAdded() {
        // implemented in mixin
        this._super();

        this.sortAgain();
      },

      // When a shift is copied with copy-button (same shift, edit vehicle)
      newShiftCopy() {
        // this.reapplyVehicleFilter.call(this);
        this.sortAgain();
      },

      // added vehicle for filtering
      onVehicleSelect(value) {
        const pattern = /^(\*|\d+(-\d+)?(?:\s?,\s?\d+(?:-\d+)?)*)$/;

        if (pattern.test(value)) {
          this.selectedVehicles.pushObject(value);
          this.applyNewFilters();
        }
      },

      // removed vehicle from filtering
      onVehicleRemove(value) {
        this.selectedVehicles.removeObject(value);
        this.applyNewFilters();
      },

      // clear all filters
      onVehicleClear() {
        this.selectedVehicles.clear();
        this.applyNewFilters();
      }

    }
  });

  _exports.default = _default;
});