define("commander/models/vehicle", ["exports", "@ember-data/model", "ember-api-actions", "commander/utils/utils"], function (_exports, _model, _emberApiActions, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    settings: Ember.inject.service(),
    uiNotification: Ember.inject.service(),
    qpService: Ember.inject.service(),
    i18n: Ember.inject.service(),
    store: Ember.inject.service(),
    vehicleLocations: Ember.inject.service(),
    typeName: 'vehicle',
    number: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    active: (0, _model.attr)('boolean'),
    version: (0, _model.attr)('string'),
    capacityConfigs: (0, _model.attr)(),
    attributes: (0, _model.attr)(),
    operator: (0, _model.attr)(),
    phoneNumber: (0, _model.attr)('string'),
    externalId: (0, _model.attr)('string'),
    dispatchPriority: (0, _model.attr)('number'),
    dispatchArea: (0, _model.attr)(),
    priceFactor: (0, _model.attr)('number'),
    useShift: (0, _model.attr)('boolean'),
    contract: (0, _model.attr)(),
    // note: just a confirmation comment for shiftOngoing:
    // should not be initialized true unless there's currently active shift because vehicle-row relies on that
    shiftOngoing: false,
    // make it computed so each instance of the class has its own set of flags
    // 'notify' flag defines if there was a notification regarding the event. Set to 'true' by default to prevent notifications from appearing during init
    absentEventState: Ember.computed(function () {
      return {
        highlight: false,
        notify: true
      };
    }),
    isDriving: Ember.computed('id', 'vehicleLocations.latestLocations.@each.id', function () {
      return this.vehicleLocations.latestLocations.some(i => i.get('vehicleNumber') === this.id);
    }),
    isAbsent: Ember.computed('shiftOngoing', 'isDriving', function () {
      return this.shiftOngoing && !this.isDriving;
    }),
    // TODO: move this functionality somewhere else, this should not be in a model. Try to get rid of `observer` somehow
    absentEventStateObserver: Ember.observer('isAbsent', 'settings.events.vehiclePing.{highlight,notify}', function () {
      this.set('absentEventState.highlight', this.isAbsent && this.get('settings.events.vehiclePing.highlight'));

      if (_utils.default.shouldTriggerEventNotify(this, this.isAbsent, this.get('settings.events.vehiclePing.notify'), 'absentEventState.notify')) {
        //this.get('uiNotification').playAudio();
        this.uiNotification.showDesktopNotification(this.i18n.t('notification.vehicleAbsent', {
          vehicleNumber: this.id
        }), () => {
          this.qpService.setQP('vehicle', this.id);
          window.focus();
        });
      }
    }),
    nameAndNumber: Ember.computed('name', 'number', function () {
      return `${this.number} (${this.name})`;
    }),

    init() {
      this._super(...arguments); // activate observers


      this.absentEventStateObserver();
    },

    getCurrentRoute() {
      return this.store.peekAll('route').filter(i => i && `${i.vehicleNumber}` === `${this.id}`).sort((a, b) => new Date(b.get('startTime')).getTime() - new Date(a.get('startTime')).getTime()).find(i => i && i.isOngoing());
    },

    // ember-api-actions start here
    getOffers: (0, _emberApiActions.memberAction)({
      path: 'offers',
      type: 'get'
    }),
    getCandidateOffers: (0, _emberApiActions.memberAction)({
      path: 'offers/candidates',
      type: 'get'
    })
  });

  _exports.default = _default;
});