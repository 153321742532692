define("commander/pods/components/settings-event-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C/Ciuekk",
    "block": "{\"symbols\":[],\"statements\":[[10,\"td\"],[12],[1,[30,[36,2],[[30,[36,1],[\"settings.\",[35,0]],null]],null]],[13],[2,\"\\n\"],[10,\"td\"],[12],[1,[30,[36,5],null,[[\"type\",\"name\",\"change\",\"checked\"],[\"checkbox\",\"highlight\",[30,[36,4],[[32,0],\"setHighlight\"],null],[35,3,[\"highlight\"]]]]]],[13],[2,\"\\n\"],[10,\"td\"],[12],[1,[30,[36,5],null,[[\"type\",\"name\",\"change\",\"checked\"],[\"checkbox\",\"notify\",[30,[36,4],[[32,0],\"setNotify\"],null],[35,3,[\"notify\"]]]]]],[13]],\"hasEval\":false,\"upvars\":[\"eventId\",\"concat\",\"t\",\"event\",\"action\",\"input\"]}",
    "meta": {
      "moduleName": "commander/pods/components/settings-event-row/template.hbs"
    }
  });

  _exports.default = _default;
});