define("commander/pods/components/vehicle-xselector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3fdlAchD",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"vehicle-selector\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"\"],[12],[2,\"\\n        \"],[1,[30,[36,1],null,[[\"class\",\"placeholder\",\"title\",\"labelKey\",\"valueKey\",\"freeText\",\"value\",\"model\",\"onSelect\",\"onClear\"],[\"x-select-theme\",[30,[36,0],[\"label.selectVehicle\"],null],[30,[36,0],[\"label.selectVehicle\"],null],\"nameAndNumber\",\"number\",false,[32,0,[\"vehicle\"]],[32,0,[\"allVehicles\"]],[32,0,[\"onVehicleSelect\"]],[32,0,[\"onVehicleClear\"]]]]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"accept-buttons\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-outline-secondary\"],[16,\"disabled\",[30,[36,2],[[32,0,[\"vehicle\"]]],null]],[24,4,\"button\"],[4,[38,3],[\"click\",[32,0,[\"onSubmit\"]]],null],[12],[1,[30,[36,4],null,[[\"icon\",\"size\"],[\"save\",\"lg\"]]]],[13],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-outline-secondary red\"],[16,\"disabled\",[30,[36,2],[[32,0,[\"vehicle\"]]],null]],[24,4,\"button\"],[4,[38,3],[\"click\",[32,0,[\"onForceSubmit\"]]],null],[12],[1,[30,[36,4],null,[[\"icon\",\"size\"],[\"save\",\"lg\"]]]],[13],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-outline-secondary red\"],[24,4,\"button\"],[4,[38,3],[\"click\",[32,0,[\"onCancel\"]]],null],[12],[1,[30,[36,4],null,[[\"icon\",\"size\"],[\"ban\",\"lg\"]]]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"x-select\",\"not\",\"on\",\"fa-icon\"]}",
    "meta": {
      "moduleName": "commander/pods/components/vehicle-xselector/template.hbs"
    }
  });

  _exports.default = _default;
});