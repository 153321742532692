define("commander/helpers/hhmm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hhmm = hhmm;
  _exports.default = void 0;

  /**
   * Converts a date string or date object into time string hhmm.
   * @param {Array} params First item in array is the one which is converted.
   */
  function hhmm(params) {
    const date = params[0];

    if (date) {
      return moment(date).format('LT');
    }

    return '';
  }

  var _default = Ember.Helper.helper(hhmm);

  _exports.default = _default;
});