define("commander/pods/components/map-route-points/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global L */
  // import { computed } from '@ember/object';
  const compareLocations = (a, b) => a.lat === b.lat && a.lng === b.lng;

  class MapRoutePoints extends _component.default {
    // @computed('args.route.stops.[]', 'args.omitTrip')
    get stops() {
      const omitTrip = this.args.omitTrip;
      return (this.args.route.stops || []).reduce((a, i) => {
        if (!omitTrip || !(compareLocations(i, omitTrip.start) || compareLocations(i, omitTrip.end))) {
          const location = L.latLng(i.lat, i.lng);

          if (location) {
            a.push({
              location,
              color: 'blue'
            });
          }
        }

        return a;
      }, []);
    }

  }

  _exports.default = MapRoutePoints;
});