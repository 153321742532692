define("commander/helpers/ddmmyyyy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ddmmyyyy = ddmmyyyy;
  _exports.default = void 0;

  function ddmmyyyy(params) {
    const date = params[0];

    if (date) {
      return moment(date).format('L');
    }

    return '';
  }

  var _default = Ember.Helper.helper(ddmmyyyy);

  _exports.default = _default;
});