define("commander/instance-initializers/nes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  // import ENV from 'commander/config/environment';
  function setupSubscriptions(nesClient, appInstance) {
    const dataService = appInstance.lookup('service:data-update');
    dataService.setupSubscriptions(nesClient);
  } // @todo: cleanup after SSE tested and verified to work


  function initialize(appInstance) {
    setupSubscriptions(null, appInstance); // const client = new nes.Client(ENV.APP.api.ws);
    // setupSubscriptions(client, appInstance);
    // client.onError = err => {
    //     console.error('WS error: ' + err);
    // };
    // client.onConnect = () => {
    //     console.debug(`WS connected to ${ENV.APP.api.ws}`);
    // };
    // client.onUpdate = msg => {
    //     console.log('GOT MSG', msg);
    // };
    // client.connect(err => {
    //     if (err) {
    //         console.error(`unable to open ws connection to ${ENV.APP.api.ws}: ${err.message}`);
    //         console.error(err);
    //     }
    // });
  }

  var _default = {
    name: 'nes',
    initialize
  };
  _exports.default = _default;
});