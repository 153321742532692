define("commander/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OcQbcmxK",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"vinka-viewport login-page \",[34,0,[\"customerStyle\"]]]]],[12],[2,\"\\n    \"],[8,\"nav-bar\",[],[[\"@login\",\"@auth\"],[[30,[36,3],[[32,0],[35,2],\"login\"],null],[34,0,[\"auth\"]]]],null],[2,\"\\n\\n    \"],[8,\"leaflet-map\",[],[[\"@lat\",\"@lng\",\"@zoom\",\"@zoomControl\"],[[34,0,[\"map\",\"initLat\"]],[34,0,[\"map\",\"initLng\"]],[34,0,[\"map\",\"zoom\"]],[34,0,[\"map\",\"zoomControl\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[35,0,[\"map\",\"tomtom\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[8,\"tile-layer\",[],[[\"@url\"],[[30,[36,1],[\"https://{s}.api.tomtom.com/map/1/tile/basic/main/{z}/{x}/{y}.png?key=\",[35,0,[\"map\",\"tileLayer\",\"key\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[8,\"tile-layer\",[],[[\"@url\"],[[34,0,[\"map\",\"tileLayer\",\"url\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"model\",\"concat\",\"send\",\"action\",\"if\"]}",
    "meta": {
      "moduleName": "commander/templates/login.hbs"
    }
  });

  _exports.default = _default;
});