define("commander/pods/components/message-detail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/HIeQwyc",
    "block": "{\"symbols\":[\"@message\"],\"statements\":[[10,\"div\"],[14,0,\"vehicle-detail\"],[12],[2,\"\\n    \"],[10,\"h5\"],[14,0,\"card-title\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"messageDetails.title\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[8,\"detail-property-row\",[],[[\"@title\",\"@value\"],[\"messageDetails.sendTime\",[32,1,[\"sendTime\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,1],[[32,1,[\"sendTime\"]]],null]],[2,\"\\n        \"],[1,[30,[36,2],[[32,1,[\"sendTime\"]]],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"detail-property-row\",[],[[\"@title\",\"@value\"],[\"messageDetails.expirationTime\",[32,1,[\"expiration\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,1],[[32,1,[\"expiration\"]]],null]],[2,\"\\n        \"],[1,[30,[36,2],[[32,1,[\"expiration\"]]],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"detail-property-row\",[],[[\"@title\",\"@value\"],[\"messageDetails.vehicles\",[32,1,[\"vehicles\"]]]],null],[2,\"\\n\\n    \"],[8,\"detail-property-row\",[],[[\"@title\",\"@value\"],[\"messageDetails.message\",[32,1,[\"message\"]]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"hhmm\",\"ddmmyyyy\"]}",
    "meta": {
      "moduleName": "commander/pods/components/message-detail/template.hbs"
    }
  });

  _exports.default = _default;
});