define("commander/pods/components/itinerary-shift/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fCMoej2U",
    "block": "{\"symbols\":[\"@event\"],\"statements\":[[10,\"div\"],[14,0,\"grid-row mod-shift\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"td type\"],[12],[2,\"\\n        \"],[1,[30,[36,2],null,[[\"icon\"],[[30,[36,1],[[32,0,[\"isStart\"]],\"car\",\"home\"],null]]]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"td time\"],[12],[2,\"\\n        \"],[1,[30,[36,3],[[32,1,[\"time\"]]],null]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"td timediff\"],[12],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"td route\"],[12],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"td status\"],[12],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"td description\"],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"isStart\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,0],[\"vehicleDetails.itinerary.shiftStart\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[1,[30,[36,0],[\"vehicleDetails.itinerary.shiftEnd\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"fa-icon\",\"hhmm\"]}",
    "meta": {
      "moduleName": "commander/pods/components/itinerary-shift/template.hbs"
    }
  });

  _exports.default = _default;
});