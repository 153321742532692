define("commander/pods/components/vehicle-number/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XzXKxaSU",
    "block": "{\"symbols\":[\"@route\",\"@isDelivered\"],\"statements\":[[6,[37,1],[[32,1,[\"vehicleNumber\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,1],[[30,[36,3],[[32,2]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,2],null,[[\"icon\"],[\"eye-slash\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[30,[36,0],[[32,0,[\"includedVehicles\",\"length\"]],1],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        [\"],[1,[32,1,[\"vehicleNumber\"]]],[2,\"]\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[32,1,[\"vehicleNumber\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[32,1,[\"dispatchVehicle\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"animation-pulse\"],[12],[1,[32,1,[\"dispatchVehicle\"]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[32,0,[\"includedVehicles\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,0,[\"includedVehicles\",\"length\"]],1],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[14,5,\"color: #aca1a1\"],[12],[2,\"[\"],[1,[32,0,[\"includedVehicles\"]]],[2,\"]\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"span\"],[12],[2,\"PRE\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"fa-icon\",\"not\"]}",
    "meta": {
      "moduleName": "commander/pods/components/vehicle-number/template.hbs"
    }
  });

  _exports.default = _default;
});