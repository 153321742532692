define("commander/pods/components/capacities-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/CPBg0P9",
    "block": "{\"symbols\":[\"cap\"],\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"orderedCapacities\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,1,[\"text\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[15,0,[31,[[34,0,[\"class\"]],\" capacity-text\"]]],[15,\"title\",[31,[[32,1,[\"name\"]]]]],[12],[2,\"\\n            \"],[1,[32,1,[\"text\"]]],[2,\"\\n            \"],[10,\"span\"],[14,0,\"capacity-count\"],[12],[1,[32,1,[\"count\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[32,1,[\"icon\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"capacity-def\"],[15,\"title\",[31,[[32,1,[\"name\"]]]]],[12],[2,\"\\n            \"],[8,\"fa-icon\",[],[[\"@icon\",\"@prefix\"],[[31,[[32,1,[\"icon\"]]]],[31,[[32,1,[\"prefix\"]]]]]],null],[2,\"\\n            \"],[10,\"span\"],[14,0,\"capacity-count\"],[12],[1,[32,1,[\"count\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"attr\",\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "commander/pods/components/capacities-list/template.hbs"
    }
  });

  _exports.default = _default;
});