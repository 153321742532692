define("commander/utils/utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parseVehicleOption = parseVehicleOption;
  _exports.vehicleOptionsToNumbers = vehicleOptionsToNumbers;
  _exports.default = void 0;

  // Get property if exists otherwise undefined
  function gp(obj, key) {
    return key.split('.').reduce(function (o, x) {
      return typeof o === 'undefined' || o === null ? o : o[x];
    }, obj);
  }

  function normalizeTrip(t, id, vehicleNumber = null) {
    t.vehicleNumber = vehicleNumber;
    t.stop = {
      pick: Object.assign({
        lat: t.start.lat,
        lng: t.start.lng,
        tripId: id,
        promisedTime: t.ride ? t.ride.promisedPickTime : null,
        requestedTime: t.anchor === 'pick' ? t.requestedTime : null
      }, t.ride ? t.ride.pick : {}),
      drop: Object.assign({
        lat: t.end.lat,
        lng: t.end.lng,
        tripId: id,
        promisedTime: t.ride ? t.ride.promisedDropTime : null,
        requestedTime: t.anchor === 'drop' ? t.requestedTime : null
      }, t.ride ? t.ride.drop : {})
    };
  }

  function normalizeStop(stop) {
    stop.lat = stop.location.lat;
    stop.lng = stop.location.lng;
  }

  function shouldTriggerEventNotify(obj, triggerCondition, eventSetting, notifyFlag) {
    /* small comment here how it works. We set the 'notify' to true ONLY if:
     *   - the setting is on and EVENT is not late
     *  We set the 'notify' to false ONLY if:
     *   - the appropriate setting is off - to prevent notifications from firing when the setting is turned on
     *   - the setting is on and EVENT is late and is not notified already
     * */
    if (eventSetting) {
      if (triggerCondition) {
        if (obj.get(notifyFlag)) {
          obj.set(notifyFlag, false);
          return true;
        }
      } else {
        obj.set(notifyFlag, true);
      }
    } else {
      obj.set(notifyFlag, false);
    }

    return false;
  }

  function parseVehicleOption(o) {
    return `${o}`.replace(/(\d+)\s+(?=\d)/g, '$1,') // replace all spaces between two numbers with commas
    .replace(/\s+/g, '') // strip ALL spaces/tabs/new lines/etc.
    .split(',') // split into chunks
    .filter(i => /^\d+(-\d+)?$/.test(i)); // check if matches the pattern `number` or `number-number`
  }

  function vehicleOptionsToNumbers(vehicleNumbers, options) {
    return options // convert strings of numbers and ranges into an array of numbers
    .reduce((a, i) => {
      let numbers = i.split('-') // cast to int
      .map(i => +i) // make sure in ascending order, otherwise we'll have a problem here...
      .sort((a, b) => a - b);

      if (numbers.length === 2) {
        // get an array of numbers from n to m
        numbers = Array.from({
          length: numbers[1] - numbers[0] + 1
        }, (_, i) => numbers[0] + i);
      }

      return [...a, ...numbers];
    }, []) // exclude the numbers that do not belong to existing vehicles
    .filter(i => vehicleNumbers.includes(i));
  }

  var _default = {
    gp,
    normalizeTrip,
    normalizeStop,
    shouldTriggerEventNotify
  };
  _exports.default = _default;
});