define("commander/utils/ui-constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DETAIL_VIEW_EVENTS = void 0;
  const DETAIL_VIEW_EVENTS = {
    stopClick: 'stopClick'
  };
  _exports.DETAIL_VIEW_EVENTS = DETAIL_VIEW_EVENTS;
  var _default = {
    DETAIL_VIEW_EVENTS
  };
  _exports.default = _default;
});