define("commander/components/select-dropdown", ["exports", "ember-select/components/select-dropdown"], function (_exports, _selectDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _selectDropdown.default;
    }
  });
});