define("commander/pods/components/break-row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['isOnBreak'],
    tagName: 'tr',
    hover: false,
    isShowingRemoveConfirm: false,
    isEditing: false,
    isSaving: false,
    isOnBreak: false,
    notifications: Ember.inject.service(),
    i18n: Ember.inject.service(),
    store: Ember.inject.service(),
    isOnBreakWatch: Ember.observer('break.isOnBreak', function () {
      const isOnBreak = this.get('break.isOnBreak');
      this.set('isOnBreak', isOnBreak);
    }),

    init() {
      this._super(...arguments);

      this.set('isOnBreak', this.get('break.isOnBreak'));
    },

    didInsertElement() {
      if (this.get('break.isNew')) {
        this.startEdit();
      }

      this._super(...arguments);
    },

    mouseEnter() {
      this.set('hover', true);
    },

    mouseLeave() {
      this.set('hover', false);
    },

    actions: {
      cancelEdit() {
        this.set('isEditing', false);
        this.set('periodStart', this.get('break.periodStart'));
        this.set('periodEnd', this.get('break.periodEnd'));

        if (this.get('break.isNew')) {
          this.sendAction('cancelNew', this['break']);
        }
      },

      saveEdit() {
        const backupStart = this.get('break.periodStart');
        const backupEnd = this.get('break.periodEnd');
        const backupDuration = this.get('break.duration');
        const inputStart = this.periodStart;
        const inputEnd = this.periodEnd;
        const inputDuration = this.duration * 60;
        inputStart.setSeconds(0, 0);
        inputEnd.setSeconds(0, 0);
        const br = this['break'];
        br.set('periodStart', inputStart);
        br.set('periodEnd', inputEnd);
        br.set('duration', inputDuration);
        br.set('vehicleNumber', this.vehicleNumber);
        this.set('isEditing', false);
        this.set('isSaving', true);
        br.save().then(() => {
          console.log('break save done');
          this.set('isSaving', false);
        }, error => {
          const errorCode = error.errors[0].detail.split(':')[0];
          this.set('isSaving', false);
          this.set('isEditing', true);
          this.errorPopup(br.get('isNew') ? 'add' : 'update', errorCode, true, {
            periodStart: br.get('startTimeLocal'),
            periodEnd: br.get('endTimeLocal'),
            vehicle: br.get('vehicleNumber')
          });
          br.set('periodStart', backupStart);
          br.set('periodEnd', backupEnd);
          br.set('duration', backupDuration);
        });
      },

      removeBreak() {
        this.set('isShowingRemoveConfirm', true);
      },

      hideModal() {
        this.set('isShowingRemoveConfirm', false);
      },

      removeConfirmed() {
        this.set('isShowingRemoveConfirm', false);
        this.sendAction('removeBreak', this['break']);
      },

      onBreakStartSelect(val) {
        this.set('periodStart', val);
      },

      onBreakEndSelect(val) {
        this.set('periodEnd', val);
      }

    },

    startEdit() {
      this.set('isEditing', true);
      this.set('periodStart', this.get('break.periodStart'));
      this.set('periodEnd', this.get('break.periodEnd'));
      this.set('duration', this.get('break.duration') / 60);
    },

    errorPopup(operation, code, autoClear = true, options = {}) {
      this.notifications.error(this.i18n.t(`breakList.errors.${operation}.${code}`, options), {
        cssClasses: 'commander-notification',
        autoClear,
        clearDuration: 5000
      });
    }

  });

  _exports.default = _default;
});