define("commander/pods/components/nav-bar/component", ["exports", "commander/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['navbar', 'navbar-dark', 'bg-inverse'],
    qpService: Ember.inject.service('qpService'),
    auth: Ember.inject.service(),
    logoUrl: '/' + (_environment.default.APP.logoUrl && _environment.default.APP.logoUrl.length > 0) ? _environment.default.APP.logoUrl : 'images/VINKA-W_smaller.png',
    isFollowingVehicles: Ember.computed('qpService.qps.followVehicles', function () {
      return this.qpService.getQP('followVehicles');
    }),
    actions: {
      logout() {
        this.auth.logout();
      },

      toggleFollowVehicles() {
        this.qpService.setQP('followVehicles', !this.qpService.getQP('followVehicles'));
      },

      login() {
        this.login();
      }

    }
  });

  _exports.default = _default;
});