define("commander/serializers/vehicle", ["exports", "commander/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeResponse(store, primaryModelClass, payload
    /*, id, requestType*/
    ) {
      // vehicles might miss `id`. In this case add id with the value from the `number`
      payload.data.forEach(v => v.id = v.id || v.attributes.number);
      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});