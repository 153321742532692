define("commander/pods/components/map-trip/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ewdbwM0F",
    "block": "{\"symbols\":[\"@weight\",\"@trip\",\"@hover\"],\"statements\":[[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,2,[\"route\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"map-route-points\",[],[[\"@route\",\"@omitTrip\",\"@weight\"],[[32,2,[\"route\"]],[32,2],4]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,0],[[32,2,[\"path\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"polyline-layer\",[],[[\"@weight\",\"@color\",\"@opacity\",\"@locations\"],[[32,1],\"black\",0.7,[32,2,[\"path\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"map-trip-endpoint\",[],[[\"@hover\",\"@location\",\"@color\"],[[32,3],[32,2,[\"start\"]],\"green\"]],null],[2,\"\\n\\n    \"],[8,\"map-trip-endpoint\",[],[[\"@hover\",\"@location\",\"@color\"],[[32,3],[32,2,[\"end\"]],\"red\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "commander/pods/components/map-trip/template.hbs"
    }
  });

  _exports.default = _default;
});