define("commander/helpers/inline-style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class InlineStyleHelper extends Ember.Helper {
    compute([name, value]) {
      return Ember.String.htmlSafe(value ? `${name}: ${value};` : '');
    }

  }

  _exports.default = InlineStyleHelper;
});