define("commander/pods/components/confirm/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Jm3OUaXY",
    "block": "{\"symbols\":[\"@cancel\",\"@title\",\"&default\",\"@cancelLabel\",\"@confirm\",\"@confirmLabel\"],\"statements\":[[8,\"modal-dialog\",[],[[\"@onClose\",\"@targetAttachment\",\"@translucentOverlay\"],[[32,1],\"center\",false]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"modal-content\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"modal-header\"],[12],[2,\"\\n            \"],[10,\"h5\"],[14,0,\"modal-title\"],[12],[1,[32,2]],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"modal-body\"],[12],[2,\"\\n            \"],[18,3,null],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"modal-footer\"],[12],[2,\"\\n            \"],[11,\"button\"],[24,0,\"btn btn-secondary\"],[24,\"data-dismiss\",\"modal\"],[24,4,\"button\"],[4,[38,0],[\"click\",[32,1]],null],[12],[1,[32,4]],[13],[2,\"\\n            \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[24,4,\"button\"],[4,[38,0],[\"click\",[32,5]],null],[12],[1,[32,6]],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"on\"]}",
    "meta": {
      "moduleName": "commander/pods/components/confirm/template.hbs"
    }
  });

  _exports.default = _default;
});