define("commander/pods/components/message-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hFCfLRo1",
    "block": "{\"symbols\":[\"message\"],\"statements\":[[10,\"table\"],[14,0,\"table table-sm table-hover\"],[14,5,\"table-layout: fixed;\"],[12],[2,\"\\n    \"],[10,\"thead\"],[12],[2,\"\\n        \"],[10,\"tr\"],[12],[2,\"\\n            \"],[8,\"sortable-th\",[],[[\"@sortBy\",\"@sortType\",\"@class\",\"@sort\"],[\"sendTime\",\"date\",\"header-col-sent\",[30,[36,1],[[32,0],\"sort\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[1,[30,[36,2],[\"messenger.sent\"],null]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n\\n            \"],[8,\"sortable-th\",[],[[\"@sortBy\",\"@sortType\",\"@class\",\"@sort\"],[\"message\",\"string\",\"header-col-message\",[30,[36,1],[[32,0],\"sort\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[1,[30,[36,2],[\"messenger.message\"],null]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n\\n            \"],[8,\"sortable-th\",[],[[\"@sortBy\",\"@sortType\",\"@class\",\"@sort\"],[\"expiration\",\"date\",\"header-col-sent\",[30,[36,1],[[32,0],\"sort\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[1,[30,[36,2],[\"messenger.expiration\"],null]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"message-row\",[],[[\"@messageClicked\",\"@message\"],[[30,[36,1],[[32,0],[35,0]],null],[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"messageClicked\",\"action\",\"t\",\"sorted\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "commander/pods/components/message-table/template.hbs"
    }
  });

  _exports.default = _default;
});