define("commander/pods/components/vehicle-detail/component", ["exports", "commander/mixins/shift-list", "commander/mixins/break-list", "commander/utils/ui-constants"], function (_exports, _shiftList, _breakList, _uiConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_shiftList.default, _breakList.default, {
    classNames: ['vehicle-detail'],
    store: Ember.inject.service(),
    messenger: Ember.inject.service(),
    storeTrips: null,
    storeRoutes: null,
    allShifts: null,
    allLocations: null,
    routeData: {},

    init() {
      this._super(...arguments);

      this.set('allBreaks', this.store.peekAll('break'));
      this.set('storeTrips', this.store.peekAll('trip'));
      this.set('storeRoutes', this.store.peekAll('route'));
      this.set('allShifts', this.store.peekAll('shift'));
      this.set('allLocations', this.store.peekAll('latest-vehicle-location'));
      this.setRouteData();
    },

    latestLocationTime: Ember.computed('allLocations.[]', 'vehicle.number', function () {
      const loc = this.store.peekRecord('latest-vehicle-location', this.get('vehicle.number'));

      if (loc) {
        return loc.get('gps.timestamp');
      }

      return '';
    }),
    breaks: Ember.computed('allBreaks.[]', 'vehicle.number', function () {
      return this.allBreaks.filterBy('vehicleNumber', this.get('vehicle.number'));
    }),
    vehicleShifts: Ember.computed('allShifts.[]', function () {
      return this.filterVehicleShifts();
    }),
    vehicleTripsObserver: Ember.observer('storeTrips.[]', function () {
      Ember.run.debounce(this, this.setRouteData, 1000);
    }),
    vehicleRouteObserver: Ember.observer('storeRoutes.[]', function () {
      // this and trip observer can be called quite often so preventing too many refreshes
      Ember.run.debounce(this, this.setRouteData, 1000);
    }),

    filterVehicleShifts() {
      return this.allShifts.filterBy('vehicleNumber', this.get('vehicle.number'));
    },

    getRoutesFiltered() {
      return this.storeRoutes.filterBy('vehicleNumber', this.get('vehicle.number'));
    },

    setRouteData() {
      // TODO sometimes the function is called on the destroyed object because of `debounce` usage. There are two solutions here:
      //  1. check `isDestroyed` flag before performing any actions (implemented)
      //  2. `debounce` return can be used for canceling in one of `Destroy` hooks. (better approach?)
      if (this.isDestroyed) {
        return;
      }

      this.set('routeData', {
        routes: this.getRoutesFiltered(),
        trips: this.storeTrips
      });
    },

    actions: {
      onStopClick(trip) {
        this.detailEvent(_uiConstants.DETAIL_VIEW_EVENTS.stopClick, trip);
      }

    }
  });

  _exports.default = _default;
});