define("commander/pods/components/messenger-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "shH+4MeM",
    "block": "{\"symbols\":[],\"statements\":[[11,\"a\"],[24,0,\"messenger-link\"],[4,[38,0],[\"click\",[32,0,[\"handleClick\"]]],null],[12],[2,\"\\n    \"],[1,[30,[36,1],null,[[\"icon\"],[\"comment-dots\"]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"on\",\"fa-icon\"]}",
    "meta": {
      "moduleName": "commander/pods/components/messenger-link/template.hbs"
    }
  });

  _exports.default = _default;
});