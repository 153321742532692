define("commander/pods/components/attribute-icons/component", ["exports", "@glimmer/component", "commander/config/environment"], function (_exports, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_ATTRIBUTES = _environment.default.APP.attributes;

  class AttributeIcons extends _component.default {
    get visibleAttributes() {
      const attributes = this.args.attributes;
      const allAttributes = this.args.allAttributes || [];
      const attributeDisplaySettings = allAttributes.reduce((acc, curr) => {
        if (curr.display) {
          acc[curr.id] = curr.display;
        }

        return acc;
      }, {});
      const translatedAttributeNames = allAttributes.reduce((acc, curr) => {
        acc[curr.id] = curr.name;
        return acc;
      }, {});
      const display = Object.assign({}, DEFAULT_ATTRIBUTES, attributeDisplaySettings);
      const attrs = (attributes || []).filter(a => !!display[a.id]);
      return attrs.map(a => {
        return {
          id: a.id,
          name: translatedAttributeNames[a.id],
          icon: display[a.id].icon,
          text: display[a.id].text,
          class: display[a.id].class,
          prefix: display[a.id].prefix,
          border: !!display[a.id].border
        };
      });
    }

  }

  _exports.default = AttributeIcons;
});