define("commander/pods/components/route-status-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lAp15Arj",
    "block": "{\"symbols\":[\"@route\"],\"statements\":[[10,\"span\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,3],[[32,0,[\"statusIcon\"]],\"sync\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],null,[[\"icon\",\"title\",\"spin\"],[[32,0,[\"statusIcon\"]],[32,1,[\"routeStatus\"]],true]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],null,[[\"icon\",\"title\"],[[32,0,[\"statusIcon\"]],[32,1,[\"routeStatus\"]]]]]],[2,\"\\n    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"fa-icon\",\"statusIcon\",\"if\",\"eq\"]}",
    "meta": {
      "moduleName": "commander/pods/components/route-status-icon/template.hbs"
    }
  });

  _exports.default = _default;
});