define("commander/helpers/yes-no", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.yesNo = yesNo;
  _exports.default = void 0;

  function yesNo(something) {
    return something && something[0] ? 'yes' : 'no';
  }

  var _default = Ember.Helper.helper(yesNo);

  _exports.default = _default;
});